import { Progress, Alert, Spin, Row, Col, Tag } from 'antd'
import Scrollbars from 'react-custom-scrollbars'
import { dynamicTitle, playerUrl } from '../../../utils/config'
import PageHeader from '../../PageHeader/pageHeader'
import { useEffect, useState } from 'react'

const OpenedTemplate = ({
  isVideoCreating,
  isGenerationProgressVisible,
  openedTemplate,
  generationProgress,
  handleNavigateBackToTemplates,
}) => {
  const [token, setToken] = useState(null)

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [])

  return (
    <>
      {isVideoCreating && isGenerationProgressVisible && (
        <>
          <Progress percent={generationProgress} status="active" strokeColor="#4868FF" />
          <Alert
            message="Video generation is in progress…"
            description="Please wait while we are generating a content for your video. It might take a while."
            type="info"
            showIcon
            icon={<Spin />}
            style={{ marginTop: '8px', marginBottom: '12px' }}
          />
        </>
      )}
      <PageHeader
        style={{ padding: '0 0 16px 8px' }}
        onBack={handleNavigateBackToTemplates}
        title={openedTemplate.name}
      />
      <Scrollbars
        className={`template-scrollbar ${
          isVideoCreating ? 'creating-video' : openedTemplate?.name ? 'opened-template-name' : 'opened-template'
        }`}
      >
        <Row justify="start" gutter={8}>
          <Col xs={24} sm={24} md={24} lg={16}>
            {openedTemplate.url ? (
              <iframe
                src={`${playerUrl}/${openedTemplate._id}${token ? `?token=${token}` : ''}`}
                title={dynamicTitle || 'Elai.io video player'}
                className="template-media player"
                frameBorder="0"
                allowFullScreen
                scrolling="no"
              ></iframe>
            ) : (
              <img
                src={openedTemplate.thumbnail}
                style={{ marginBottom: '16px' }}
                className="template-media"
                alt="thumbnail"
              />
            )}
            <div className="template-drawer-tags">
              {openedTemplate.template?.tags?.map((tag) => (
                <Tag key={tag} style={{ marginBottom: '5px' }}>
                  {tag}
                </Tag>
              ))}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Row gutter={8} style={{ margin: 0 }}>
              {openedTemplate.slides.map((s) => (
                <Col
                  xs={openedTemplate?.data?.format === '9_16' ? 8 : 12}
                  sm={openedTemplate?.data?.format === '9_16' ? 8 : 12}
                  md={openedTemplate?.data?.format === '9_16' ? 4 : openedTemplate?.data?.format === '1_1' ? 8 : 12}
                  lg={openedTemplate?.data?.format === '9_16' ? 6 : openedTemplate?.data?.format === '1_1' ? 8 : 12}
                  key={s.id}
                >
                  <img src={s.screenshot} className="template-media" />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Scrollbars>
    </>
  )
}

export default OpenedTemplate
