import rectangle from '../assets/images/rectangle.png'
import circle from '../assets/images/circle.png'
import triangle from '../assets/images/triangle.png'
import ellipse from '../assets/images/ellipse.png'

export const elements = [
  {
    title: 'Images',
    type: 'image',
    elements: [
      {
        id: 'image1',
        thumbnail:
          'https://images.pexels.com/photos/421927/pexels-photo-421927.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
        url: 'https://images.pexels.com/photos/421927/pexels-photo-421927.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
      },
      {
        id: 'image2',
        thumbnail:
          'https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
        url: 'https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
      },
      {
        id: 'image3',
        thumbnail:
          'https://images.pexels.com/photos/1666667/pexels-photo-1666667.jpeg?auto=compress&cs=tinysrgb&dpr=1&fit=crop&h=200&w=280',
        url: 'https://images.pexels.com/photos/1666667/pexels-photo-1666667.jpeg?auto=compress&cs=tinysrgb&h=650&w=940',
      },
    ],
  },
  {
    title: 'Videos',
    type: 'video',
    elements: [
      {
        id: 'video1',
        thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/assets/thumbnails/1114908_Man_Woman_Job_1920x1080.jpeg',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/assets/videos/1114908_Man_Woman_Job_1920x1080.mp4',
      },
      {
        id: 'video2',
        thumbnail:
          'https://d3u63mhbhkevz8.cloudfront.net/assets/thumbnails/6036412_Team_Business_Office_1920x1080.jpeg',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/assets/videos/6036412_Team_Business_Office_1920x1080.mp4',
      },
      {
        id: 'video3',
        thumbnail: 'https://d3u63mhbhkevz8.cloudfront.net/assets/thumbnails/1477025_Business_1920x1080.jpeg',
        url: 'https://d3u63mhbhkevz8.cloudfront.net/assets/videos/1477025_Business_1920x1080.mp4',
      },
    ],
  },
  {
    title: 'Shapes',
    type: 'shape',
    elements: [
      {
        id: 'rect',
        thumbnail: rectangle,
      },
      {
        id: 'circle',
        thumbnail: circle,
      },
      {
        id: 'triangle',
        thumbnail: triangle,
      },
    ],
  },
  {
    title: 'Stickers',
    type: 'sticker',
    elements: [
      {
        id: 'sticker1',
        thumbnail:
          'https://media3.giphy.com/media/QpyF0jsO26GWKTWctv/giphy-preview.gif?cid=c1042575pfsmfny0s09eujl2fgeddwaq8pfr22tnqagyrasu&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=s',
        previewUrl:
          'https://media3.giphy.com/media/QpyF0jsO26GWKTWctv/giphy.gif?cid=c1042575pfsmfny0s09eujl2fgeddwaq8pfr22tnqagyrasu&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
        url: 'https://media3.giphy.com/media/QpyF0jsO26GWKTWctv/giphy.gif?cid=c1042575pfsmfny0s09eujl2fgeddwaq8pfr22tnqagyrasu&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
      },
      {
        id: 'sticker2',
        thumbnail:
          'https://media0.giphy.com/media/vpPSgM1F3yy8J0aJA0/giphy-preview.gif?cid=c1042575unvdzr2iejo0ztzesuu9ywa5hahs390cjg7tq4jf&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=s',
        previewUrl:
          'https://media0.giphy.com/media/vpPSgM1F3yy8J0aJA0/giphy.gif?cid=c1042575unvdzr2iejo0ztzesuu9ywa5hahs390cjg7tq4jf&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
        url: 'https://media0.giphy.com/media/vpPSgM1F3yy8J0aJA0/giphy.gif?cid=c1042575unvdzr2iejo0ztzesuu9ywa5hahs390cjg7tq4jf&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
      },
      {
        id: 'sticker3',
        thumbnail:
          'https://media3.giphy.com/media/AachnVpcIlUbUFSlnF/giphy-preview.gif?cid=c1042575iegakznsv199ntll9jxwh7cn32z9vza4inc86m8n&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=s',
        previewUrl:
          'https://media3.giphy.com/media/AachnVpcIlUbUFSlnF/giphy.gif?cid=c1042575iegakznsv199ntll9jxwh7cn32z9vza4inc86m8n&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
        url: 'https://media3.giphy.com/media/AachnVpcIlUbUFSlnF/giphy.gif?cid=c1042575iegakznsv199ntll9jxwh7cn32z9vza4inc86m8n&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
      },
    ],
  },
  {
    title: 'GIFs',
    type: 'gif',
    elements: [
      {
        id: 'gif1',
        thumbnail:
          'https://media2.giphy.com/media/I0g3jREJnV4gaGTZlx/giphy-preview.gif?cid=c10425757r0r6ijnmenmz8sav461shw7egutoxwmeecia135&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=g',
        previewUrl:
          'https://media2.giphy.com/media/I0g3jREJnV4gaGTZlx/giphy.gif?cid=c10425757r0r6ijnmenmz8sav461shw7egutoxwmeecia135&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
        url: 'https://media2.giphy.com/media/I0g3jREJnV4gaGTZlx/giphy.gif?cid=c10425757r0r6ijnmenmz8sav461shw7egutoxwmeecia135&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
      },
      {
        id: 'gif2',
        thumbnail:
          'https://media2.giphy.com/media/gg0sAafx4kZPrJff3K/giphy-preview.gif?cid=c1042575gda1oizabxplf0zqk5eiobmw5dzhj9rdjklxdgup&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=g',
        previewUrl:
          'https://media2.giphy.com/media/gg0sAafx4kZPrJff3K/giphy.gif?cid=c1042575gda1oizabxplf0zqk5eiobmw5dzhj9rdjklxdgup&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
        url: 'https://media2.giphy.com/media/gg0sAafx4kZPrJff3K/giphy.gif?cid=c1042575gda1oizabxplf0zqk5eiobmw5dzhj9rdjklxdgup&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
      },
      {
        id: 'gif3',
        thumbnail:
          'https://media3.giphy.com/media/hug59RPo5pxowKxw2J/giphy-preview.gif?cid=c1042575ddhc1ji5nuy2hdi03o7lr405u0mxa0r4538u25m9&ep=v1_gifs_gifId&rid=giphy-preview.gif&ct=g',
        previewUrl:
          'https://media3.giphy.com/media/hug59RPo5pxowKxw2J/giphy.gif?cid=c1042575ddhc1ji5nuy2hdi03o7lr405u0mxa0r4538u25m9&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
        url: 'https://media3.giphy.com/media/hug59RPo5pxowKxw2J/giphy.gif?cid=c1042575ddhc1ji5nuy2hdi03o7lr405u0mxa0r4538u25m9&ep=v1_gifs_gifId&rid=giphy.gif&ct=g',
      },
    ],
  },
  {
    title: 'Lottie',
    type: 'lottie',
    elements: [
      {
        id: 'lottie1',
        url: 'https://lottie.host/f37b3b30-7b1e-4c33-a792-33cd8d4add14/MSihLVFV85.json',
      },
      {
        id: 'lottie2',
        url: 'https://lottie.host/564b20ea-30a8-43ac-af4e-40052a8cdfd5/37Ez7Z747x.json',
      },
      {
        id: 'lottie3',
        url: 'https://lottie.host/6429902a-8cb3-4dc8-983c-2f2682e4bf4f/Ixh0lVidd9.json',
      },
    ],
  },
]

export const shapes = {
  title: 'Shapes',
  type: 'shape',
  elements: [
    {
      id: 'rect',
      thumbnail: rectangle,
    },
    {
      id: 'circle',
      thumbnail: circle,
    },
    {
      id: 'triangle',
      thumbnail: triangle,
    },
    {
      id: 'ellipse',
      thumbnail: ellipse,
    },
  ],
}
