import { Button } from 'antd'
import Lottie from 'lottie-web'
import { Scrollbars } from 'react-custom-scrollbars'
import { useEffect, useRef, useState, Fragment } from 'react'

import { Giphy } from './giphy'
import { Pexels } from './pexels'
import { ElementItem } from './elementItem'
import LottieUploader from './lottieUploader'

import { elements, shapes } from '../../../../../data/elements'

import { STYLE } from './constants'

export const useElementsTabsState = (props) => {
  const { setActiveObjectModifier, setIsOpenGradient, video, slideCanvas, setBackArrow, backArrow } = props

  const lottieContainer = useRef([])

  const [lottieUrl, setLottieUrl] = useState()
  const [activeTab, setActiveTab] = useState('all')

  useEffect(() => {
    if (backArrow.isVisible) {
      setActiveTab(backArrow.tab)
    }
  }, [backArrow])

  useEffect(() => {
    elements
      .find((el) => el.type === 'lottie')
      .elements.forEach((lottie, index) => {
        const lottieDivContainer = lottieContainer.current[index]
        if (lottieDivContainer) {
          lottieDivContainer.innerHTML = ''
        }
        Lottie.loadAnimation({
          container: lottieDivContainer,
          path: lottie.url,
          autoplay: true,
        })
      })
  }, [])

  const handleTabClick = (key) => setActiveTab(key)

  const handleItemClick = (element, file) => () => {
    const objectType = element.type
    setBackArrow({ isVisible: false, tab: activeTab })
    if (objectType === 'video') {
      setActiveObjectModifier({
        newObject: 'video',
        url: file.url,
        thumbnail: file.thumbnail,
      })
    } else if (objectType === 'gif' || objectType === 'sticker') {
      setActiveObjectModifier({
        newObject: 'gif',
        url: file.url,
        previewUrl: file.previewUrl,
      })
    } else if (objectType === 'shape') {
      setActiveObjectModifier({ newObject: 'shape', type: file.id })
    } else if (objectType === 'lottie') {
      setActiveObjectModifier({ newObject: 'lottie', url: file.url })
    } else {
      setActiveObjectModifier({ newObject: 'image', url: file.url })
    }
  }

  const onPexelsImageChooseFile = (file) => {
    setBackArrow({ isVisible: false, tab: activeTab })
    setActiveObjectModifier({ newObject: 'image', url: file.url })
  }

  const onPexelsVideoChooseFile = (file) => {
    setBackArrow({ isVisible: false, tab: activeTab })
    const videos = file.video_files.filter((f) => f.quality === 'hd' && f.width <= 1920)
    videos.sort((a, b) => b.width - a.width)
    setActiveObjectModifier({
      newObject: 'video',
      url: videos.length ? videos[0].link : file.video_files[0].link,
      thumbnail: file.screenshot,
    })
  }

  const onStickerGifChoose = (url, previewUrl) => {
    setBackArrow({ isVisible: false, tab: activeTab })
    setActiveObjectModifier({ newObject: 'gif', url, previewUrl })
  }

  const onShapeItemClick = (shape) => () => {
    setBackArrow({ isVisible: false, tab: activeTab })
    setIsOpenGradient(false)
    setActiveObjectModifier({ newObject: 'shape', type: shape.id })
  }

  const tabs = [
    {
      key: 'all',
      label: 'All',
      children: (
        <Scrollbars className="scrollbar" style={STYLE.minHeight500}>
          {elements.map((element) => (
            <Fragment key={element.type}>
              <div className="item-title-container media-items-controls">
                <h4 style={STYLE.marginBottom0}>{element.title}</h4>
                <Button type="link" style={STYLE.padding0} onClick={() => setActiveTab(element.type)}>
                  See all
                </Button>
              </div>
              <div className="tab-all-items" style={STYLE.paddingBottom16}>
                {element.elements.map((file, i) => (
                  <Fragment key={file.id}>
                    {element.type === 'lottie' ? (
                      <div
                        className="element-item"
                        ref={(el) => (lottieContainer.current[i] = el)}
                        onClick={handleItemClick(element, file)}
                      />
                    ) : (
                      <ElementItem thumbnail={file.thumbnail} onClickItem={handleItemClick(element, file)} />
                    )}
                  </Fragment>
                ))}
              </div>
            </Fragment>
          ))}
        </Scrollbars>
      ),
    },
    {
      key: 'image',
      label: 'Images',
      children: <Pexels filesType="image" onChooseFile={onPexelsImageChooseFile} />,
    },
    {
      key: 'video',
      label: 'Videos',
      children: <Pexels filesType="video" onChooseFile={onPexelsVideoChooseFile} />,
    },
    {
      key: 'shape',
      label: 'Shapes',
      children: (
        <div className="search-items-container">
          {shapes.elements.map((shape) => (
            <ElementItem key={shape.id} thumbnail={shape.thumbnail} onClickItem={onShapeItemClick(shape)} />
          ))}
        </div>
      ),
    },
    {
      key: 'sticker',
      label: 'Stickers',
      children: <Giphy type="stickers" onChooseFile={onStickerGifChoose} />,
    },
    {
      key: 'gif',
      label: 'GIFs',
      children: <Giphy type="gifs" onChooseFile={onStickerGifChoose} />,
    },
    {
      key: 'lottie',
      label: 'Lottie',
      children: (
        <LottieUploader
          video={video}
          lottieUrl={lottieUrl}
          slideCanvas={slideCanvas}
          setLottieUrl={setLottieUrl}
          setActiveObjectModifier={setActiveObjectModifier}
        />
      ),
    },
  ]

  return { activeTab, handleTabClick, tabs }
}
