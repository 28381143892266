import { track } from '../utils/analytics'
import { Layout, Space, Button } from 'antd'
import { RightOutlined, LogoutOutlined } from '@ant-design/icons'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { dynamicLogoUrl, signupLinksEnabled } from '../utils/config'
import { useStore } from '../store'
import logo from '../assets/images/logo.svg'
import logoElaiAcademy from '../assets/images/academy/logo.png'
const { Header } = Layout

export default () => {
  const authStore = useStore((stores) => stores.authStore)
  const location = useLocation()
  const isAcademyPage = location.pathname.includes('/academy')

  const onClickLogout = () => {
    authStore.logout()
    track('log_out')
  }

  return (
    <Layout className={`layout ${isAcademyPage ? 'academy' : null}`}>
      <Header className={`guest-header ${isAcademyPage ? 'academy-header' : null}`}>
        {isAcademyPage ? (
          <div>
            <Link to="/academy">
              <img className="academy-logo" src={logoElaiAcademy} alt="Elai academy Logo" />
            </Link>
          </div>
        ) : (
          <>
            <div style={{ width: '80%' }}>
              <Link to={signupLinksEnabled ? '/' : false}>
                <img className="logo" src={dynamicLogoUrl || logo} alt="Elai Logo" />
              </Link>
            </div>
            <Space>
              {signupLinksEnabled ? (
                authStore.user ? (
                  <>
                    {location.pathname !== '/poll' && (
                      <Button type="primary" onClick={onClickLogout} icon={<LogoutOutlined />}>
                        Logout
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <p className="login-link-question">
                      {location.pathname === '/signup' ? `Already have an account?` : `Don't have an account yet?`}
                    </p>
                    <Link
                      to={location.pathname === '/signup' ? '/login' : '/signup'}
                      state={location.state ?? { from: location }}
                    >
                      <Button type="primary" icon={<RightOutlined />} className="btn-arrow">
                        {location.pathname === '/signup' ? `Sign in` : `Sign up`}
                      </Button>
                    </Link>
                  </>
                )
              ) : null}
            </Space>
          </>
        )}
      </Header>
      <Outlet />
    </Layout>
  )
}
