import ReactGA from 'react-ga4'
import { request } from './api'

const track = (event, properties) => {
  ReactGA.event(event, properties)
  // don't show any errors
  request({ method: 'post', url: `/users/analytics`, data: { event, properties }, customErrorHandler: () => {} })
}

export { track }
