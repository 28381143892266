import { useState, useMemo, useEffect } from 'react'
import { Row, Col, Space, Tooltip, Button } from 'antd'
import { QuestionCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useStore } from '../../../store'
import ColorPicker from './colorPicker'
import { request } from '../../../utils/api'
import { normalizeColor, getLuma, grabVideoColors, applyVideoColors } from './helpers'

const sortByLumas = (colors) => {
  const lumas = colors.map((c) => ({ color: c, luma: getLuma(c) }))
  lumas.sort((a, b) => a.luma - b.luma)
  return lumas
}

const CorporateColors = ({ color, onChangeColor, visible, video, onVideoColorsChange, setChangesCounter }) => {
  const {
    user: { account },
    refreshSession,
  } = useStore((stores) => stores.authStore)
  const [selectedColor, setSelectedColor] = useState()
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false)
  const [palleteApplied, setPalleteApplied] = useState(false)
  const videoColors = useMemo(() => [...grabVideoColors(video)], [video, visible])

  // temp hotfix
  account.corporateColors = account.corporateColors || []

  const isGranted = account.plan !== 'basic'
  const canApplyAll = account.corporateColors.length > 0 && isGranted

  const onSelectNewColor = async (color) => {
    setSelectedColor(color)
    onChangeColor(color)
  }

  const addNewColor = async () => {
    const cleanColor = normalizeColor(selectedColor)
    if (!account.corporateColors.includes(cleanColor)) {
      await request({ method: 'post', url: 'accounts/corporateColor', data: { color: cleanColor } })
      await refreshSession()
    }
    setSelectedColor(null)
  }

  useEffect(() => {
    setPalleteApplied(false)
  }, [visible])

  useEffect(() => {
    if (isOpenColorPicker || !selectedColor) return
    addNewColor()
  }, [isOpenColorPicker])

  const removeColor = async (color) => {
    await request({ method: 'delete', url: `accounts/corporateColor/${encodeURIComponent(color)}` })
    await refreshSession()
  }

  const checkLumasEqual = (a, b) => {
    if (a.length !== b.length) return false
    return a.every((la, idx) => la.color === b[idx].color)
  }

  const compareLumas = (corporateLumas, videoLumas) => {
    const matchedCorporateLumas = corporateLumas.filter(
      (cl) => videoLumas.findIndex((vl) => cl.color === vl.color) !== -1,
    )
    return checkLumasEqual(matchedCorporateLumas, videoLumas)
  }

  const applyAll = async () => {
    const corporateLumas = sortByLumas(account.corporateColors)
    const videoLumas = sortByLumas(videoColors)

    if (compareLumas(corporateLumas, videoLumas) || palleteApplied) return

    const factor = corporateLumas.length / videoLumas.length
    const colorMap = videoLumas.reduce((colorMap, vl, idx) => {
      colorMap[vl.color] = corporateLumas[Math.min(Math.round(idx * factor), corporateLumas.length - 1)].color
      return colorMap
    }, {})
    applyVideoColors(
      video,
      (color) => colorMap[color] || color,
      (_, obj) => !obj || obj.type !== 'group' || obj.objects.length < 3,
    )
    setPalleteApplied(true)

    onVideoColorsChange(() => setPalleteApplied(false))
    setChangesCounter((c) => c + 1)
  }

  return (
    <>
      <Row style={{ marginTop: 14 }} align="top">
        <Col>
          <Space>
            <h4>Brand Colors</h4>
            <Tooltip title="Store colors available for all users in your account and apply to any video">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 14 }} align="top">
        <Col>
          <Space wrap={true}>
            {account.corporateColors.map((c) => {
              const itemClass = `color-item ${c === color ? 'active' : 'inactive'}`
              return (
                <button type="button" key={c} className={itemClass} onClick={() => onChangeColor(c)}>
                  <div className="color-value" style={{ backgroundColor: c }}></div>
                  <CloseCircleOutlined
                    className="color-remove"
                    title="Remove color"
                    onClick={(e) => {
                      e.stopPropagation()
                      removeColor(c)
                    }}
                  />
                </button>
              )
            })}
            {isGranted && (
              <ColorPicker
                color={color}
                onChangeColor={onSelectNewColor}
                isOpen={isOpenColorPicker}
                setIsOpen={setIsOpenColorPicker}
              />
            )}
            {!isGranted && (
              <Tooltip title="Upgrade to Advanced or Enterprise plan to use brand colors">
                <div onClick={() => (window.location.href = '#subscription')}>
                  <ColorPicker color={color} onChangeColor={onSelectNewColor} disabled={true} />
                </div>
              </Tooltip>
            )}
            {canApplyAll && !palleteApplied && (
              <Tooltip title="Apply corporate palette to video">
                <Button size="small" type="dashed" style={{ fontSize: '12px' }} onClick={applyAll}>
                  Apply all
                </Button>
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default CorporateColors
