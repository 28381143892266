import axios from 'axios'
import { track } from '../../../../../utils/analytics'
import { apiPexelsKey } from '../../../../../utils/config'
import { Input, Button, Spin, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { backgrounds } from '../../../../../data/backgrounds'
import { ElementItem } from './elementItem'
import '../elements.less'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'

const { Search } = Input

const STYLE = {
  width100: { width: '100%' },
  linkColor: { color: '#8c8d8f' },
  minHeight500: { minHeight: 500 },
  openPreview: { width: '100%', maxWidth: '700px', objectFit: 'contain', maxHeight: '75vh' },
  emptyResponse: { textAlign: 'center', marginTop: 20 },
  spin: { width: '100%', height: '50px', padding: '15px' },
  providedBy: { color: '#8c8d8f', fontSize: '12px', textAlign: 'center', marginTop: 10 },
}

const plusOutlinedIcon = <PlusOutlined />

// NOTE: temporary solution for thumbnails CORS caching
backgrounds.forEach((bg) => (bg.thumbnail += '?nocache'))

export const Pexels = (props) => {
  const { filesType, onChooseFile } = props
  const notification = useElaiNotification()

  const [loader, setLoader] = useState()
  const [filesData, setFilesData] = useState()
  const [preview, setPreview] = useState()
  const [isOpenPreviewLoader, setIsOpenPreviewLoader] = useState(false)
  const [isOpenPreview, setIsOpenPreview] = useState(false)

  const pexelsUrl = filesType === 'image' ? 'https://api.pexels.com/v1' : 'https://api.pexels.com/videos'

  const addFieldsToObject = (data) => {
    return data[filesType === 'image' ? 'photos' : 'videos'].map((file) => ({
      ...file,
      type: filesType,
      url: filesType === 'image' ? file.src.large : file.video_files[0].link,
    }))
  }

  const onSearch = async (value) => {
    if (filesData) setFilesData(null)
    if (!value) {
      return fetchMedia(`${pexelsUrl}/popular/?per_page=30`)
    }
    fetchMedia(`${pexelsUrl}/search/?per_page=30&query=${value}`)
  }

  const showMore = async () => {
    if (filesData.next_page) {
      setLoader(true)
      try {
        const request = await axios.get(filesData.next_page, {
          headers: {
            Authorization: apiPexelsKey,
          },
        })
        const type = filesType === 'image' ? 'photos' : 'videos'
        setLoader(false)
        const addedFieldsToObject = await addFieldsToObject(request.data)
        setFilesData({
          ...request.data,
          [type]: [...filesData[type], ...addedFieldsToObject],
        })
      } catch (error) {
        notification.error({ message: error.message, duration: null })
        setLoader(false)
      }
    }
  }

  const fetchMedia = useCallback(async (url) => {
    setLoader(true)
    try {
      const request = await axios.get(url, {
        headers: {
          Authorization: apiPexelsKey,
        },
      })
      setLoader(false)
      const addedFieldsToObject = await addFieldsToObject(request.data)
      console.log(request.data)
      setFilesData({
        ...request.data,
        [filesType === 'image' ? 'photos' : 'videos']: addedFieldsToObject,
      })
    } catch {
      notification.error({
        message: 'Stock service error',
        description:
          'Unfortunately pexels.com server is not responding. Please go directly to pexels.com, unsplash.com, pixabay.com or other stock service and upload media to Elai manually.',
        duration: null,
      })
      setLoader(false)
    }
  })

  const handlePreview = async (file) => {
    setPreview({
      ...preview,
      url: file.url,
      thumbnail: file.thumbnail || file.src?.small,
      type: file.type,
      screenshot: filesType === 'video' ? file.image : '',
      video_files: filesType === 'video' ? file.video_files : '',
    })
  }

  const handleCancel = () => {
    setIsOpenPreview(false)
  }

  useEffect(() => {
    if (filesType === 'video') {
      fetchMedia(`${pexelsUrl}/popular/?per_page=30`)
    }
  }, [])

  const onAddClick = () => {
    onChooseFile(preview)
    setIsOpenPreview(false)
  }

  const onLoadedMetadata = () => setIsOpenPreviewLoader(false)

  const filesList =
    !filesData && filesType === 'image' && !loader
      ? backgrounds
      : filesData?.[filesType === 'image' ? 'photos' : 'videos']

  return (
    <div className="search-media">
      <Search
        placeholder="Search awesome media on Pexels"
        className="media-items-controls"
        onSearch={onSearch}
        enterButton
      />
      <div className="search-media-wrapper">
        <Scrollbars className="pexels-scrollbar scrollbar" style={STYLE.minHeight500}>
          <div className="search-items-container">
            {filesList?.map((file, index) => (
              <Fragment key={index}>
                <ElementItem
                  // file.video_pictures[0].picture -> file.image (changed by CORS policy in OPTIONS request)
                  // if it'll possible needs to be reverted as pictures size is smaller
                  thumbnail={filesType === 'image' ? file.src?.tiny || file.thumbnail : file.image}
                  isVisibleActions={true}
                  onClickItem={() => {
                    handlePreview(file)
                    setIsOpenPreview(true)
                  }}
                  onChooseFile={() => {
                    track('editor_stock_media_added', { provider: 'pexels', type: filesType })
                    onChooseFile({
                      ...file,
                      screenshot: filesType === 'video' ? file.image : '',
                    })
                  }}
                />
              </Fragment>
            ))}
          </div>
          <div className="media-items-controls">
            {!loader && filesData && filesData.next_page && (
              <Button type="primary" ghost block onClick={showMore}>
                Show more
              </Button>
            )}
            {loader && <Spin style={STYLE.width100} />}
            {filesData?.[filesType === 'image' ? 'photos' : 'videos'].length ? (
              <p style={STYLE.providedBy}>
                Photos provided by{' '}
                <a href="https://www.pexels.com/" target="_blank" style={STYLE.linkColor} rel="noreferrer">
                  Pexels
                </a>
              </p>
            ) : null}
            {filesData && !filesData[filesType === 'image' ? 'photos' : 'videos'].length && (
              <p style={STYLE.emptyResponse}>Nothing found</p>
            )}
          </div>
        </Scrollbars>
      </div>
      <Modal
        open={isOpenPreview}
        closable={false}
        width={700}
        className="media-preview-modal"
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button type="primary" icon={plusOutlinedIcon} key="add" onClick={onAddClick}>
            Add
          </Button>,
        ]}
      >
        {isOpenPreviewLoader || !preview ? (
          <Spin style={STYLE.spin} />
        ) : preview.type === 'video' ? (
          <video
            style={STYLE.openPreview}
            src={preview.url}
            controlsList="nodownload"
            crossOrigin="anonymous"
            onLoadedMetadata={onLoadedMetadata}
            muted
            controls
          />
        ) : (
          <img
            alt="example"
            style={STYLE.openPreview}
            src={preview.url}
            onLoad={onLoadedMetadata}
            crossOrigin="anonymous"
          />
        )}
      </Modal>
    </div>
  )
}
