import { Typography } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import Icon from '../../../../../components/Icon'

import {
  MARKER_ICON,
  MARK_REGEX,
  MARK_EMOJI,
  PAUSE_EMOJI,
  PAUSE_ICON,
  PHONEME_ICON,
  formatSpeech,
  HISTORY_SIZE,
  MULTIPLE_PHONEME_REGEX,
  SINGLE_PHONEME_REGEX,
  SPECIAL_CHARS_PHONEME_REGEX,
} from './constants'

import { useStore } from '../../../../../store'
import { request } from '../../../../../utils/api'
import { findFirstDifferenceIndex, normalizePauses, replace } from './helpers'
import { uniqueId } from '../../../../../utils/helpers'
import { track } from '../../../../../utils/analytics'
import { getDefaultVoice } from '../../../../../utils/videos'
import { resetTimeOfObjectsWithMarkers } from '../../../../../utils/canvas/canvas'
import useClickOutside from '../../../../../hooks/useClickOutside'
import { os } from '../../../constants'
import { osMap } from '../../../../../utils/constants'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'
import { useSlideDuration } from '../../../../../hooks/useSlideDuration'

const { Text } = Typography

const phonemeValuePosition = 13

let mark_pointer = 0
let markMap = []
let slideId = ''

export const useSpeechEditorState = (props) => {
  const { data, updateSlide, isSpeechUndo, undoLastChanges } = props
  const notification = useElaiNotification()
  const speechContextMenuRef = useRef()
  const textareaRef = useRef(null)
  const phonemeButtonRef = useRef(null)
  const phonemeMenuRef = useRef(null)
  const phonemeInputRef = useRef(null)
  const phonemeInputWrapperRef = useRef(null)

  const [wordForDictionary, setWordForDictionary] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const [history, setHistory] = useState([])
  const [isOpenPhonemeModal, setIsOpenPhonemeModal] = useState(false)
  const [isOpenSpeechContextMenu, setIsOpenSpeechContextMenu] = useState(false)
  const [isOpenPhonemeMenu, setIsOpenPhonemeMenu] = useState(false)
  const [isOpenPhonemeInput, setIsOpenPhonemeInput] = useState(false)
  const [editingPhonemeText, setEditingPhonemeText] = useState('')
  const [textAreaCursorPosition, setTextAreaCursorPosition] = useState(null)

  const voices = useStore((stores) => stores.voicesStore.voices)
  const { status: accountStatus, plan: accountPlan } = useStore((stores) => stores.authStore.user.account)
  const { markMap: storedMarkMap, insertMarks } = useStore((stores) => stores.videosStore)

  // Initialize with first loaded slide speech
  const [speech, setSpeech] = useState(data.speech)
  const [isSpeechUpdatedExternally, setIsSpeechUpdatedExternally] = useState(true)

  const { getApproxDuration } = useSlideDuration({ slide: data })

  // If slide was changed update speech
  useEffect(() => {
    if (slideId !== data.id) {
      // reset history as it can copy prev slide speech into new one
      setHistory([data.speech])
      setIsSpeechUpdatedExternally(true)
      slideId = data.id
    }
  }, [data.id])

  // if speech changed outside of editor
  useEffect(() => {
    if (data.speech) {
      if (isSpeechUpdatedExternally || isSpeechUndo) {
        mark_pointer = 0
        // retrieve markers id from speech
        const availableMarkers = [...data.speech.matchAll(MARK_REGEX)].map((match) => match[1])

        // create a map
        markMap = availableMarkers.map((mark, index) => ({
          [`${MARK_EMOJI[index]}`]: mark,
        }))

        const userFriendly = replace(data.speech)
        // swap text tags with emoji representation
        const replacedText = userFriendly.replace(/<mark name=['"](\d+)['"] \/>/g, (match, index) => {
          const emoji = MARK_EMOJI[mark_pointer] || ''
          mark_pointer++

          return emoji
        })
        insertMarks(markMap)

        setSpeech(replacedText)

        setIsSpeechUpdatedExternally(false)
      } else if (markMap.length && !storedMarkMap.length) {
        // force update for marks in store when user completely changes a speech
        insertMarks(markMap)
      }
    } else {
      setSpeech('')
    }
  }, [data.speech, isSpeechUpdatedExternally])

  const restoreCursorPosition = () => {
    if (!textAreaCursorPosition) return
    const { startPosition, endPosition } = textAreaCursorPosition
    if (!startPosition || !endPosition) return
    textareaRef.current.selectionStart = startPosition
    textareaRef.current.selectionEnd = endPosition
    setTextAreaCursorPosition(null)
  }

  // Handle user typing
  const onSpeechChange = (event) => {
    const { value: newSpeech } = event.target

    // Check if speech has actually changed
    if (speech === newSpeech) {
      return
    }

    // Set the new speech value
    setSpeech(newSpeech)

    // Find emojis in the new speech
    const emojisInText = MARK_EMOJI.filter((emoji) => newSpeech.includes(emoji))

    // Update markMap if emojis have changed
    if (emojisInText.length < markMap.length) {
      markMap = markMap.filter((item) => emojisInText.includes(Object.keys(item)[0]))
      mark_pointer = markMap.length
      insertMarks(markMap)
    }

    // Preserve cursor position
    const textarea = textareaRef.current
    const startPosition = textarea.selectionStart
    const endPosition = textarea.selectionEnd

    // Restore cursor position after re-render
    textarea.selectionStart = startPosition
    textarea.selectionEnd = endPosition
  }

  // update slide speech
  const saveSpeechChanges = useCallback(async () => {
    // Update history
    if (history[history.length - 1] !== speech) {
      const newHistory = [...history, speech]
      if (newHistory.length > HISTORY_SIZE) newHistory.unshift()
      setHistory(newHistory)
    }

    // convert to API readable format
    const normalized = normalizePauses(speech)

    const emojiPattern = new RegExp(
      `(${MARK_EMOJI.map((emoji) => emoji.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`,
      'gu',
    )
    const values = markMap.map((obj) => Object.values(obj)[0])
    const keys = markMap.map((obj) => Object.keys(obj)[0])
    // swap emojis into text representation

    // Change enojis into tags
    const replacedText = normalized.replace(emojiPattern, (match) => {
      const index = keys.indexOf(match)
      return `<mark name="${values[index]}" />`
    })

    // if there is no changes do nothing
    if (replacedText === data.speech) {
      // TODO: figure out how to fix more elegant
      return
    }

    // remove extra whitespaces
    const formatedSpeech = formatSpeech(replacedText)
    const { objects, requestUpdateCanvas } = resetTimeOfObjectsWithMarkers(data.canvas.objects, values)

    updateSlide({
      speech: formatedSpeech,
      duration: null,
      approxDuration: getApproxDuration({ ...data, speech: formatedSpeech }),
      status: 'edited',
      language: data.language,
      canvas: { ...data.canvas, objects },
      updateCanvas: requestUpdateCanvas,
    })
  }, [speech, data.speech, data.language, data.canvas])

  // detecting speech language
  const detectAndSetLanguage = async (value) => {
    if (value?.charAt(0) === data.speech?.charAt(0)) {
      return
    }
    const { name: languageName } = await request({
      method: 'post',
      url: '/videos/detectLanguage',
      data: { speech: value },
    })
    if (!languageName || data.language === languageName) return
    const voice = voices.find((language) => language.name === languageName)
    if (!voice) return
    updateSlide(
      {
        language: languageName,
        ...getDefaultVoice(voices, data.avatar.gender, languageName),
      },
      { ignoreHistory: true },
    )
  }

  const restoreTextFromJson = (text) => {
    if (!text.startsWith('{')) return text
    try {
      const { clonedCanvasObject } = JSON.parse(text)
      if (!clonedCanvasObject?.text) return ''
      return clonedCanvasObject.text
    } catch {
      return text
    }
  }

  /**
   * If user insert some text
   * call language detection
   * @param {SyntheticBaseEvent} event
   */
  const onPaste = async (event) => {
    event.preventDefault()
    const data = event.clipboardData.items
    const textarea = textareaRef.current
    const selectionStart = textarea.selectionStart
    const selectionEnd = textarea.selectionEnd
    const textLength = textarea.value.length
    let allTextSelected = false
    // check for whole text replace
    if (selectionStart === 0 && selectionEnd === textLength) {
      allTextSelected = true
    }

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].kind !== 'string' || !data[i].type.match('^text/plain')) continue
      // eslint-disable-next-line no-loop-func
      data[i].getAsString(async (text) => {
        text = restoreTextFromJson(text)
        if (!text.length) return
        await detectAndSetLanguage(text)
        // if all text was replaced - then we reset all marks
        if (allTextSelected) {
          markMap = []
        } else {
          const emojisInSpeech = MARK_EMOJI.filter((emoji) => speech.includes(emoji))
          for (let index = 0; index < emojisInSpeech.length; index++) {
            const emojiIndex = text.indexOf(emojisInSpeech[index])
            if (emojiIndex !== -1) {
              if (emojiIndex >= 0 && emojiIndex < text.length) {
                // code length of emoji
                let emojiLength = text.codePointAt(emojiIndex) > 0xffff ? 2 : 1
                text = text.slice(0, emojiIndex) + text.slice(emojiIndex + emojiLength)
              }
            }
          }
        }

        insertToState(text)
      })
    }
    if (allTextSelected) {
      setTimeout(() => {
        const emojisInText = MARK_EMOJI.filter((emoji) => textarea.value.includes(emoji))

        markMap = markMap.filter((item) => emojisInText.includes(Object.keys(item)[0]))
        mark_pointer = markMap.length
        insertMarks(markMap)
      }, 500)
    }
  }

  // when state is changed - initiate speech update
  useEffect(() => {
    // retrieve all mark emojis
    const marksInText = MARK_EMOJI.filter((emoji) => speech.includes(emoji))
    // If marks count was changed
    if (marksInText.length !== markMap.length) {
      const keys = markMap.map((obj) => Object.keys(obj)[0])
      // Find the difference of previous state with new marks count
      const diff = marksInText.filter((emoji) => !keys.includes(emoji))
      // Iterate over new marks and insert them at right position
      diff.forEach((emoji) => {
        const index = marksInText.indexOf(emoji)
        const newObj = { [emoji]: `${uniqueId()}` }
        markMap.splice(index, 0, newObj)
      })

      mark_pointer = markMap.length
    }
    restoreCursorPosition()
    const timeoutId = setTimeout(saveSpeechChanges, 500)
    return () => clearTimeout(timeoutId)
  }, [speech])

  // control how to insert into textarea
  const insertToState = (textToInsert, isMark = false, isUpdateSelection = true) => {
    const textarea = textareaRef.current
    if (textarea) {
      const startPosition = textarea.selectionStart
      const endPosition = textarea.selectionEnd
      const currentValue = textarea.value

      let newValue = ''
      if (!isMark) {
        newValue = currentValue.substring(0, startPosition) + textToInsert + currentValue.substring(endPosition)
      } else {
        const regex = new RegExp(MARK_EMOJI.map((emoji) => `(${emoji})`).join('|'), 'g')
        const id = uniqueId()
        const currentEmojis = currentValue.match(regex)

        const avaliableEmojis = MARK_EMOJI.filter((emoji) => {
          return !currentEmojis?.includes(emoji)
        })
        const emojiToInsert = avaliableEmojis.shift()

        newValue = currentValue.substring(0, startPosition) + emojiToInsert + currentValue.substring(endPosition)

        // retrieve all mark emojis
        const emojis = newValue.match(regex)

        // get index of new emoji
        const index = emojis.indexOf(textToInsert)
        const newObj = { [emojiToInsert]: `${id}` }
        // map in new emoji with new id
        markMap.splice(index, 0, newObj)
        insertMarks(markMap)
      }

      setSpeech(newValue)

      /**
       * After inserting browser start re-render which changes selectionStart(end)
       * to avoid that we update state right after re-render
       */
      if (isUpdateSelection) {
        setTimeout(() => {
          textarea.selectionStart = startPosition + textToInsert.length
          textarea.selectionEnd = startPosition + textToInsert.length
          textarea.focus()
        })
      }
    }
  }

  const insertPause = useCallback(
    (event) => {
      switch (event.key) {
        case '1': {
          insertToState(PAUSE_EMOJI.small)
          break
        }
        case '2': {
          insertToState(PAUSE_EMOJI.standard)
          break
        }
        case '3': {
          insertToState(PAUSE_EMOJI.medium)
          break
        }
        case '4': {
          insertToState(PAUSE_EMOJI.large)
          break
        }
        default: {
          return
        }
      }
      track('editor_pause_added')
    },
    [insertToState],
  )

  const cutText = () => {
    const textarea = textareaRef.current

    if (textarea) {
      const startPosition = textarea.selectionStart
      const endPosition = textarea.selectionEnd

      const currentValue = textarea.value
      const newValue = currentValue.substring(0, startPosition) + currentValue.substring(endPosition)

      // textarea.value = newValue
      setSpeech(newValue)

      textarea.selectionStart = startPosition
      textarea.selectionEnd = startPosition
    }
  }

  const copyText = async () => {
    const textarea = textareaRef.current

    if (textarea) {
      const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
      if (selectedText) {
        await navigator.clipboard.writeText(selectedText)
      }
    }
  }

  const checkSelectedPhonemes = (text, selectionStart, selectionEnd) => {
    const matches = [...text.matchAll(MULTIPLE_PHONEME_REGEX)]
    return matches.some((match) => {
      const tagStart = match.index
      const tagEnd = match.index + match[0].length
      return (
        (tagStart >= selectionStart && tagEnd <= selectionEnd) ||
        (selectionStart > tagStart && selectionStart < tagEnd) ||
        (selectionEnd > tagStart && selectionEnd < tagEnd)
      )
    })
  }

  const onClickPhoneme = useCallback(() => {
    if (isOpenPhonemeMenu) return setIsOpenPhonemeMenu(false)
    if (isOpenPhonemeInput) return handleClosePhonemeInput()
    const textarea = textareaRef.current
    if (textarea) {
      const selectionStart = textarea.selectionStart
      const selectionEnd = textarea.selectionEnd
      const isSelectedPhonemes = checkSelectedPhonemes(textarea.value, selectionStart, selectionEnd)
      if (selectionStart !== selectionEnd && !isSelectedPhonemes) {
        setIsOpenPhonemeMenu(true)
        textarea.focus()
        textarea.setSelectionRange(selectionStart, selectionEnd)
      } else {
        setIsOpenPhonemeModal(true)
      }
    }
  }, [isOpenPhonemeMenu, isOpenPhonemeInput])

  const insertMarker = useCallback(() => {
    if (mark_pointer !== MARK_EMOJI.length) {
      insertToState(MARK_EMOJI[mark_pointer], true)
      mark_pointer++
      track('editor_mark_added')
    } else {
      notification.warning({
        key: 'Run out of marks',
        message: `There is no  marks left`,
        duration: 6,
      })
    }
  }, [mark_pointer, MARK_EMOJI])

  const handleSpeechContextMenuClick = useCallback(
    async (e) => {
      switch (e.key) {
        case 'context_menu_cut':
          cutText()
          break
        case 'context_menu_copy':
          copyText()
          break
        case 'context_menu_paste': {
          let text = await navigator.clipboard.readText()
          await detectAndSetLanguage(text)

          const emojisInSpeech = MARK_EMOJI.filter((emoji) => speech.includes(emoji))
          for (let index = 0; index < emojisInSpeech.length; index++) {
            const emojiIndex = text.indexOf(emojisInSpeech[index])
            if (emojiIndex !== -1) {
              if (emojiIndex >= 0 && emojiIndex < text.length) {
                // code lenght of emoji
                let emojiLength = text.codePointAt(emojiIndex) > 0xffff ? 2 : 1
                text = text.slice(0, emojiIndex) + text.slice(emojiIndex + emojiLength)
              }
            }
          }
          insertToState(text)
          break
        }
        case 'context_menu_phoneme':
          onClickPhoneme()
          break
        case '1':
        case '2':
        case '3':
        case '4': {
          insertPause(e)
          break
        }

        case 'context_menu_marker':
          insertMarker()
          break
        default:
          break
      }
      setIsOpenSpeechContextMenu(false)
    },
    [insertPause, onClickPhoneme],
  )

  const handleClickOutside = (event) => {
    if (speechContextMenuRef.current && !speechContextMenuRef.current.contains(event.target)) {
      setIsOpenSpeechContextMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [speechContextMenuRef])

  const modifierKey = os === osMap.MAC ? '⌘' : 'Ctrl+'

  const isClipboardFullySupported = useMemo(
    () => !/(Safari|Firefox)\//i.test(navigator.userAgent) || navigator.userAgent.includes('Chrome'),
    [],
  )

  const speechContextMenuItems = useMemo(() => {
    const items = [
      { key: 'context_menu_phoneme', icon: <img src={PHONEME_ICON} className="contextMenuIcon" />, label: 'Phoneme' },
      { key: 'context_menu_marker', icon: <img src={MARKER_ICON} className="contextMenuIcon" />, label: 'Mark' },
      {
        key: 'content_menu_pause_main',
        icon: <img src={PAUSE_ICON} className="contextMenuIcon" />,
        label: 'Pause',
        children: [
          { key: '1', icon: PAUSE_EMOJI.small, label: '0.25s' },
          { key: '2', icon: PAUSE_EMOJI.standard, label: '0.5s' },
          { key: '3', icon: PAUSE_EMOJI.medium, label: '1s' },
          { key: '4', icon: PAUSE_EMOJI.large, label: '1.5s' },
        ],
      },
    ]
    if (isClipboardFullySupported)
      items.unshift(
        {
          key: 'context_menu_cut',
          icon: <Icon name="cut" />,
          label: (
            <span className="menu-item-label">
              <span>Cut</span> <Text type="secondary">{modifierKey}X</Text>
            </span>
          ),
        },
        {
          key: 'context_menu_copy',
          icon: <Icon name="copy" />,
          label: (
            <span className="menu-item-label">
              <span>Copy</span> <Text type="secondary">{modifierKey}C</Text>
            </span>
          ),
        },
        {
          key: 'context_menu_paste',
          icon: <Icon name="paste" />,
          label: (
            <span className="menu-item-label">
              <span>Paste</span>
              <Text type="secondary">{modifierKey}V</Text>
            </span>
          ),
        },
        { type: 'divider' },
      )

    return items
  }, [])

  const handleOpenContextMenu = () => setIsOpenSpeechContextMenu(true)

  const voice = useMemo(() => ({ id: data.voice, provider: data.voiceProvider }), [data.voice, data.voiceProvider])

  const handleTextareaKeyDown = (event) => {
    // block default undo and instead do history change
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyZ') {
      const textarea = textareaRef.current

      if (!isFocused) return
      if (history.length < 2) return
      history.pop()
      const prevSpeech = history[history.length - 1]
      const startPosition = findFirstDifferenceIndex(prevSpeech, speech)
      const diff = Math.max(prevSpeech.length - textarea.value.length, 0)
      setTextAreaCursorPosition({ startPosition, endPosition: startPosition + diff })

      undoLastChanges()
      setIsSpeechUpdatedExternally(true)
      event.preventDefault()
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const isVoiceOver = data.canvas?.objects?.find((o) => o.type === 'avatar')?.avatarType === 'voiceover'

  const characterLimit = useMemo(() => {
    const allowLong = accountStatus === 'paid' && accountPlan !== 'basic'

    if (allowLong && (isVoiceOver || data?.avatar.limit > 60)) {
      return data.activeVoice.voiceProvider === 'azure' ? 7000 : 5000
    }
    return 1500
  }, [accountStatus, data.activeVoice, data?.avatar.limit, isVoiceOver])

  const handleClickPhonemeMenu = ({ key }) => {
    const textarea = textareaRef.current
    const selectionStart = textarea.selectionStart
    const selectionEnd = textarea.selectionEnd
    const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
    textarea.focus()
    textarea.setSelectionRange(selectionStart, selectionEnd)
    setIsOpenPhonemeMenu(false)
    if (key === 'dictionary') handleClickAddToDictionary(selectedText)
    else if (key === 'apply') handleClickApplyPhoneme(selectedText, textarea, selectionStart)
  }

  const handleClickAddToDictionary = (selectedText) => {
    if (SINGLE_PHONEME_REGEX.test(selectedText)) {
      const matches = [...selectedText.matchAll(MULTIPLE_PHONEME_REGEX)]
      const phoneme = matches[0][1]
      const word = matches[0][2]
      setWordForDictionary({ word, phoneme })
    } else {
      setWordForDictionary({ word: selectedText, phoneme: selectedText })
    }
    setIsOpenPhonemeModal(true)
  }

  const handleClickApplyPhoneme = (selectedText, textarea, selectionStart) => {
    setIsOpenPhonemeInput(true)
    const textToInsert = `<phoneme ph="${selectedText}">${selectedText}</phoneme>`
    insertToState(textToInsert, false, false)
    setEditingPhonemeText(selectedText)
    setTimeout(() => {
      phonemeInputRef.current.focus()
      textarea.selectionStart = selectionStart + phonemeValuePosition
      textarea.selectionEnd = textarea.selectionStart + selectedText.length
    })
  }

  useClickOutside([phonemeButtonRef, phonemeMenuRef, phonemeInputWrapperRef, speechContextMenuRef], () => {
    if (isOpenPhonemeMenu) setIsOpenPhonemeMenu(false)
    if (isOpenPhonemeInput) handleClosePhonemeInput()
  })

  const debouncePhonemeChange = useCallback(
    debounce(500, (v) => savePhoneme(v)),
    [],
  )

  const savePhoneme = (v) => {
    if (SPECIAL_CHARS_PHONEME_REGEX.test(v)) return
    const textarea = textareaRef.current
    const selectionStart = textarea.selectionStart
    insertToState(v, false, false)
    setTimeout(() => {
      textarea.selectionStart = selectionStart
      textarea.selectionEnd = selectionStart + v.length
    })
  }

  const onPhonemeChange = (e) => {
    setEditingPhonemeText(e.target.value)
    debouncePhonemeChange(e.target.value)
  }

  const handleClosePhonemeInput = () => {
    setIsOpenPhonemeInput(false)
    textareaRef.current.selectionEnd = textareaRef.current.selectionStart
    setEditingPhonemeText('')
  }

  return {
    voice,
    speech,
    onPaste,
    handleBlur,
    handleFocus,
    textareaRef,
    insertPause,
    insertMarker,
    onClickPhoneme,
    onSpeechChange,
    characterLimit,
    wordForDictionary,
    isOpenPhonemeModal,
    setWordForDictionary,
    speechContextMenuRef,
    handleOpenContextMenu,
    handleTextareaKeyDown,
    setIsOpenPhonemeModal,
    speechContextMenuItems,
    isOpenSpeechContextMenu,
    handleSpeechContextMenuClick,
    isOpenPhonemeMenu,
    handleClickPhonemeMenu,
    isOpenPhonemeInput,
    phonemeButtonRef,
    phonemeMenuRef,
    phonemeInputRef,
    phonemeInputWrapperRef,
    onPhonemeChange,
    handleClosePhonemeInput,
    editingPhonemeText,
  }
}
