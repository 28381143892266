import { useStore } from '../../../../store'
import Icon from '../../../../components/Icon'
import { LeavingPopconfirm } from '../leavingPopconfirm'
import { useNavigateToAnotherPage } from './useNavigateToAnotherPage'

const VideoBackButton = ({ video, isVideoSaved }) => {
  const { user } = useStore((stores) => stores.authStore)

  if (user.accountRole === 'locked') return null

  const { isLeavingPopconfirmOpen, setIsLeavingPopconfirmOpen, handleNavigate } = useNavigateToAnotherPage({
    isVideoSaved,
    navigateUrl: video.template && video.accountId === user.account.id ? `/templates` : `/videos`,
  })

  return (
    <LeavingPopconfirm
      isLeavingPopconfirmOpen={isLeavingPopconfirmOpen}
      setIsLeavingPopconfirmOpen={setIsLeavingPopconfirmOpen}
      onConfirm={() => handleNavigate(true)}
    >
      <a onClick={() => handleNavigate(false)} className="back-link">
        <Icon name="left_arrow" />
      </a>
    </LeavingPopconfirm>
  )
}

export default VideoBackButton
