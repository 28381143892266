import React, { useEffect } from 'react'
import axios from 'axios'
import { Spin } from 'antd'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { apiUrl } from '../../utils/config'
import { useStore } from '../../store'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const Auth = () => {
  const notification = useElaiNotification()
  const authStore = useStore((stores) => stores.authStore)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const navigate = useNavigate()

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (params.has('email')) {
          await axios.get(apiUrl + '/auth/verify' + search)
          notification.success({
            message: 'Thank you! Your email was verified.',
          })
          await authStore.refreshSession()
          const isJustRegistered = localStorage.getItem('isJustRegistered')
          if (isJustRegistered) {
            localStorage.removeItem('isJustRegistered')
            navigate('/', {
              state: { isJustRegistered: true },
            })
          } else {
            navigate('/')
          }
        }
      } catch (error) {
        const message = error.response?.data?.message || error.message
        notification.error({ message, duration: false })
      }
    }
    verifyEmail()
  }, [])

  return <Spin size="large" />
}

export default Auth
