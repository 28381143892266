import { useEffect, useState } from 'react'
import { Row, Col, Button } from 'antd'

import Icon from '../../../../components/Icon'
import FileUploader from '../fileUploader/fileUploader'
import LazySlider from '../../../../components/LazySlider'

import { useStore } from '../../../../store'

const style = {
  width100: { width: '100%' },
}

const photoEditorIcon = <Icon name="photo_editor" />

const FrameControls = (props) => {
  const { canvasActiveObject, setActiveObjectModifier } = props

  const [isOpenFrameUploader, setIsOpenFrameUploader] = useState(false)
  const [isOpenImageUploader, setIsOpenImageUploader] = useState(false)

  const isAdmin = useStore((stores) => stores.authStore.user.isAdmin)

  const [fetchFrames, setFetchFrames] = useState(false)

  //close uploaders when canvasActiveObject type (frame) is changed to another type
  useEffect(() => {
    setIsOpenFrameUploader(false)
  }, [canvasActiveObject.type])

  const handleApplyFrameClick = () => {
    setFetchFrames((prevState) => !prevState)
    setIsOpenFrameUploader(!isOpenFrameUploader)
    setIsOpenImageUploader(false)
  }

  const handleUploadFrameClick = () => {
    setIsOpenImageUploader(!isOpenImageUploader)
    setIsOpenFrameUploader(false)
  }

  return (
    <>
      {(canvasActiveObject.type === 'image' || canvasActiveObject.type === 'frame') && (
        <Row gutter={16}>
          <Col span={canvasActiveObject.type === 'image' ? 24 : 12}>
            <Button
              type="dashed"
              icon={canvasActiveObject.type === 'image' ? photoEditorIcon : <Icon name="pen" />}
              style={style.width100}
              onClick={handleApplyFrameClick}
            >
              {canvasActiveObject.type === 'image' ? 'Apply frame to image' : 'Edit Frame'}
            </Button>
          </Col>
          {canvasActiveObject.type === 'frame' && (
            <Col span={12}>
              <Button type="dashed" icon={photoEditorIcon} style={style.width100} onClick={handleUploadFrameClick}>
                Edit Image
              </Button>
            </Col>
          )}
        </Row>
      )}
      {(canvasActiveObject.type === 'image' || canvasActiveObject.type === 'frame') && (
        <div className={`frame-uploader ${!isAdmin && 'hide-uploader-btn'}`}>
          <FileUploader
            visible={isOpenFrameUploader}
            isVideoAllowed={false}
            isFrames={true}
            fetchFrames={fetchFrames}
            onChooseFile={(file) => {
              setActiveObjectModifier(
                canvasActiveObject.type === 'image'
                  ? { newObject: 'frame', url: file.url }
                  : { change: 'frame', url: file.url },
              )
              setIsOpenFrameUploader(false)
            }}
          />
        </div>
      )}
      {canvasActiveObject.type === 'frame' && (
        <>
          <div className="frame-uploader">
            <FileUploader
              visible={isOpenImageUploader}
              isVideoAllowed={false}
              onChooseFile={(file) => {
                setActiveObjectModifier({ change: 'image', value: file.url, async: true })
                setIsOpenImageUploader(false)
              }}
            />
          </div>
          <Row align="middle" style={{ marginTop: '16px' }}>
            <Col span={9}>
              <h4>Image scale</h4>
            </Col>
            <Col span={15}>
              <LazySlider
                value={canvasActiveObject.patternWidth}
                linkedElement={canvasActiveObject}
                linkedElementKey="scale"
                min={canvasActiveObject.width / 4}
                max={canvasActiveObject.width * 2}
                step={canvasActiveObject.width / 100}
                tooltip={{ formatter: (v) => parseInt((v * 100) / canvasActiveObject.width) + '%' }}
                onChange={(v) => setActiveObjectModifier({ change: 'scale', value: v })}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginTop: '0' }}>
            <Col span={9}>
              <h4>Image left</h4>
            </Col>
            <Col span={15}>
              <LazySlider
                value={canvasActiveObject.fill.offsetX}
                linkedElement={canvasActiveObject}
                linkedElementKey="offsetX"
                min={-canvasActiveObject.width}
                max={canvasActiveObject.width}
                step={canvasActiveObject.width / 100}
                tooltip={{ formatter: (v) => parseInt((v * 100) / canvasActiveObject.width) + '%' }}
                onChange={(v) => setActiveObjectModifier({ change: 'offsetX', value: v })}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginTop: '0' }}>
            <Col span={9}>
              <h4>Image top</h4>
            </Col>
            <Col span={15}>
              <LazySlider
                value={canvasActiveObject.fill.offsetY}
                linkedElement={canvasActiveObject}
                linkedElementKey="offsetY"
                min={-canvasActiveObject.width}
                max={canvasActiveObject.width}
                step={canvasActiveObject.width / 100}
                tooltip={{ formatter: (v) => parseInt((v * 100) / canvasActiveObject.width) + '%' }}
                onChange={(v) => setActiveObjectModifier({ change: 'offsetY', value: v })}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default FrameControls
