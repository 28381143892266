import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { isSignedUrl } from '../../../utils/helpers'
import { textTypes } from '../../../utils/canvas/canvas'
import { useStore } from '../../../store'
import { isTesting } from '../../../utils/config'
import useClickOutside from '../../../hooks/useClickOutside'

const urlWithoutCache = (url, videoId) => {
  if (isSignedUrl(url)) return url
  return `${url}?nocache=${videoId}`
}

const stripSignedUrl = (s) => {
  const url = new URL(s)
  return `${url.origin}${url.pathname}`
}

export const useSidebarState = (props) => {
  const {
    canvasActiveObject,
    isOpenSidebar,
    setIsOpenSidebar,
    setActiveTab,
    activeTab,
    setActiveObjectModifier,
    player,
    video,
    updateVideo,
  } = props

  const { user } = useStore((stores) => stores.authStore)
  const [modalData, setModalData] = useState({ isOpen: false })
  const [backArrow, setBackArrow] = useState({ isVisible: false, tab: '' })
  const [isShouldUpdateUploads, setShouldUpdateUploads] = useState(false)
  const [isOpenShortcutsPopover, setIsOpenShortcutsPopover] = useState(false)
  const shortcutsPopoverContentRef = useRef(null)
  const btnOpenShortcutsPopoverRef = useRef(null)

  const activeObjectType = useMemo(
    () =>
      canvasActiveObject?.type === 'activeSelection'
        ? canvasActiveObject.getObjects().every((obj) => obj.type === canvasActiveObject.getObjects()[0].type) ||
          canvasActiveObject.getObjects().every((obj) => textTypes.includes(obj.type))
          ? canvasActiveObject.getObjects()[0].type
          : null
        : canvasActiveObject?.type,
    [canvasActiveObject, canvasActiveObject?._objects],
  )

  /**
   * Manage sidebar when canvasActiveObject changes
   */
  useEffect(() => {
    if (canvasActiveObject) {
      if (backArrow.tab) {
        setBackArrow({ isVisible: true, tab: backArrow.tab })
      }
      const isActiveObjectText =
        (canvasActiveObject.type === 'activeSelection'
          ? canvasActiveObject.getObjects().every((obj) => textTypes.includes(obj.type))
          : textTypes.includes(canvasActiveObject.type)) || textTypes.includes(activeObjectType)

      if (!isOpenSidebar) {
        setIsOpenSidebar(true)
      }
      if (activeObjectType === 'avatar') {
        setActiveTab('presenter')
      } else if (activeObjectType === 'question') {
        setActiveTab('interactivity')
      } else if (isActiveObjectText) {
        setActiveTab('text')
      } else setActiveTab('elements')
    } else if (activeTab === 'presenter' || activeTab === 'text') {
      setActiveTab('elements')
    }

    if (!canvasActiveObject) {
      setBackArrow({ isVisible: false, tab: '' })
    }
  }, [canvasActiveObject, canvasActiveObject?._objects])

  /**
   * Deselect canvas active object if sidebar is closed
   */
  useEffect(() => {
    if (!isOpenSidebar && canvasActiveObject) {
      setActiveObjectModifier('discardActiveObject')
    }
  }, [isOpenSidebar])

  // Avoid diff function same name
  const onTabClick = useCallback(
    (key) => {
      if (!player.activePreview) {
        setActiveTab(key)
      }
      if (key === 'text') {
        setActiveObjectModifier({ newObject: 'text' })
      } else if ((key === 'elements' || key === 'interactivity') && canvasActiveObject) {
        setActiveObjectModifier('discardActiveObject')
      } else if (isTesting && key === 'presenter') localStorage.setItem('__activeObjectInit', performance.now())
      setIsOpenSidebar(true)
    },
    [player.activePreview, canvasActiveObject],
  )

  const handleChooseFile = (file) => {
    if (file.type === 'video') {
      if (file.url.includes('screen-recording')) {
        setModalData({
          isOpen: true,
          url: file.url,
          thumbnail: urlWithoutCache(file.thumbnail, video._id),
        })
      } else {
        setActiveObjectModifier({
          newObject: 'video',
          url: file.url,
          thumbnail: urlWithoutCache(file.thumbnail, video._id),
        })
      }
    } else if (file.name && file.name.endsWith('.svg')) {
      setActiveObjectModifier({ newObject: 'svg', url: file.url })
    } else if (file.type === 'gif') {
      setActiveObjectModifier({ newObject: 'gif', url: file.url })
    } else {
      setActiveObjectModifier({ newObject: 'image', url: file.url })
    }
  }

  const handleCancel = () => {
    setActiveObjectModifier({
      newObject: 'video',
      url: modalData.url,
      thumbnail: urlWithoutCache(modalData.url, video._id),
    })
    setModalData({ isOpen: false })
  }

  const handleOk = () => {
    setActiveObjectModifier({
      newObject: 'video',
      url: modalData.url,
      thumbnail: urlWithoutCache(modalData.url, video._id),
      durationFitAudio: true,
    })
    setModalData({ isOpen: false })
  }

  const displaySidebar = () => setIsOpenSidebar(!isOpenSidebar)

  const handleBackArrowClick = () => {
    setActiveObjectModifier('discardActiveObject')
  }

  const handleUploadRemoved = (mediaUrl) => {
    const url = stripSignedUrl(mediaUrl)

    if (!video.slides.some((s) => s.canvas?.objects.some((o) => o.src?.startsWith(url) || o.imgSrc?.startsWith(url))))
      return

    updateVideo(
      ({ slides }) => ({
        slides: slides.map((slide) => {
          slide.canvas.objects = slide.canvas.objects.filter(
            (o) => !o.src?.startsWith(url) && !o.imgSrc?.startsWith(url),
          )
          return slide
        }),
      }),
      { updateActiveCanvas: true },
    )
  }

  const toggleShortcutsPopover = () => {
    setIsOpenShortcutsPopover((prevState) => !prevState)
  }

  const onCloseShortcutsPopover = () => {
    if (isOpenShortcutsPopover) setIsOpenShortcutsPopover(false)
  }

  useClickOutside([shortcutsPopoverContentRef, btnOpenShortcutsPopoverRef], onCloseShortcutsPopover)

  return {
    handleOk,
    modalData,
    backArrow,
    onTabClick,
    setModalData,
    handleCancel,
    setBackArrow,
    displaySidebar,
    activeObjectType,
    handleChooseFile,
    handleBackArrowClick,
    isShouldUpdateUploads,
    user,
    setShouldUpdateUploads,
    handleUploadRemoved,
    isOpenShortcutsPopover,
    btnOpenShortcutsPopoverRef,
    shortcutsPopoverContentRef,
    onCloseShortcutsPopover,
    toggleShortcutsPopover,
  }
}
