import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ONBOARDING_TEMPLATE_ID } from '../utils/constants'
import { requestCreateVideo } from '../utils/videoCreation/videoCreation'

const useShowPage = () => {
  const navigate = useNavigate()
  const timerId = useRef(null)
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const showCoursePage = () => {
    const courseId = localStorage.getItem('courseToOpen')
    const showCoursePageAfterPoll = localStorage.getItem('showCoursePageAfterPoll')
    if (courseId && !showCoursePageAfterPoll) {
      localStorage.removeItem('courseToOpen')
      localStorage.removeItem('redirectToOnboardingVideo')
      timerId.current = setTimeout(() => navigate(`/academy/course/${courseId}`, { replace: true }))
    }
  }

  const navigateToOnboardingVideo = async (afterUpdatingPurchaseData) => {
    const redirectToOnboardingVideo = localStorage.getItem('redirectToOnboardingVideo')
    const purchase = localStorage.getItem('purchase')
    const purchaseAvatar = localStorage.getItem('purchaseAvatar')
    const purchaseVoice = localStorage.getItem('purchaseVoice')
    const purchaseData = purchase || purchaseAvatar || purchaseVoice
    const showCoursePageAfterPoll = localStorage.getItem('showCoursePageAfterPoll')
    const updatingPurchaseData = afterUpdatingPurchaseData ? false : params.has('checkout-success')

    if (redirectToOnboardingVideo && !purchaseData && !showCoursePageAfterPoll && !updatingPurchaseData) {
      const videoId = await requestCreateVideo({ data: { templateId: ONBOARDING_TEMPLATE_ID } })
      if (!videoId) return
      localStorage.removeItem('redirectToOnboardingVideo')
      navigate(`/video/${videoId}`, { state: { showOnboardingModal: true } })
    }
  }

  useEffect(() => () => clearTimeout(timerId.current), [])

  return { showCoursePage, navigateToOnboardingVideo }
}

export default useShowPage
