import { useState, useEffect, useMemo, useRef } from 'react'
import { useStore } from '../../../../../store'
import { useElaiNotification } from '../../../../../hooks/useElaiNotification'
import { resetTimeOfObjectsWithMarkers } from '../../../../../utils/canvas/canvas'
import { formatVoiceTags } from '../constants'
import { useSlideDuration } from '../../../../../hooks/useSlideDuration'

export const useVoiceModal = (props) => {
  const { data, getActiveVoice, updateVideo, setVisibleVoiceModal, updateSlide, visibleVoiceModal, voices } = props
  const notification = useElaiNotification()
  const voicePreviewAudio = useRef(null)

  /**
   * update active voice only when it really necessary
   * NOTE: different objects that are equal by value.
   */
  const _activeVoice = useMemo(
    () => ({
      language: data.language,
      voice: { id: data.voice, ...getActiveVoice() },
    }),
    [data.language, data.voice],
  )

  // state
  const [voicesList, setVoicesList] = useState([])
  const [voicesTags, setVoicesTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [activeVoice, setActiveVoice] = useState(_activeVoice)
  const [premiumVoicesVisible, setPremiumVoicesVisible] = useState(true)
  const [voicePreviewPlayer, setVoicePreviewPlayer] = useState({ id: '', state: 'idle' })

  // store
  const user = useStore((stores) => stores.authStore.user)

  const isPremiumAvailable = user.account.plan && user.account.plan !== 'basic'

  const { getApproxDuration } = useSlideDuration({})

  const isActiveVoice = (voice) =>
    voice.voice === activeVoice.voice.id || voice.playedTags?.some((t) => t.id === activeVoice.voice.id)

  // hooks
  useEffect(() => {
    // get available voices for current language
    let selectedVoices =
      voices.length > 0
        ? voices.find((language) => language.name === activeVoice.language)[data.avatar.gender].map(formatVoiceTags)
        : []

    // apply user voices if hes have one
    if (user.account.voices?.length > 0) {
      // make custom voices visible on any language
      const customVoices = user.account.voices

      selectedVoices = [
        ...customVoices
          .filter((voice) => voice.status === 3)
          .map((voice) => ({
            name: voice.name,
            voice: voice.id,
            voiceProvider: voice.provider,
          })),
        ...selectedVoices,
      ]
    }

    // exclude premium voices if they not allowed
    if (!premiumVoicesVisible) {
      selectedVoices = selectedVoices.filter((voice) => !voice.premium)
    }

    const voiceTagsVariable = new Set(
      selectedVoices
        .map((voice) => [voice.playedTags?.map((tag) => tag.name), voice.tags, voice.styleList])
        .flat(2)
        .filter((tag) => tag)
        .sort((a, b) => (a < b ? -1 : a === b ? 0 : 1)),
    )

    // filter voices by tags
    if (selectedTags.length) {
      selectedVoices = selectedVoices.filter(
        (voice) =>
          voice.tags?.some((tag) => selectedTags.some((selectedTag) => selectedTag === tag)) ||
          voice.playedTags?.some((tag) => selectedTags.some((selectedTag) => selectedTag === tag.name)) ||
          voice.styleList?.some((style) => selectedTags.some((selectedTag) => selectedTag === style)),
      )
    }

    if (voicesTags !== voiceTagsVariable) {
      setVoicesTags([...voiceTagsVariable])
    }
    if (voicesList !== selectedVoices) {
      setVoicesList(selectedVoices)
    }
  }, [
    voices,
    data.id,
    selectedTags,
    data.avatar.gender,
    activeVoice.language,
    activeVoice.voice.id,
    premiumVoicesVisible,
  ])

  /**
   * To open modal on active language/voice
   */
  useEffect(() => {
    if (visibleVoiceModal) {
      const activeVoiceResult = getActiveVoice()
      setActiveVoice({
        language: data.language,
        voice: { id: data.voice, ...activeVoiceResult },
      })
    }
  }, [visibleVoiceModal])

  // functions
  const createVoicePreviewAudio = async (id, url) => {
    if (!url) return notification.warning({ message: 'Sorry, the voice preview is currently unavailable.' })
    const audio = new Audio(url)
    setVoicePreviewPlayer({ id, state: 'loading' })
    audio.onended = () => setVoicePreviewPlayer({ id, state: 'idle' })
    audio.onloadeddata = () => setVoicePreviewPlayer({ id, state: 'playing' })
    voicePreviewAudio.current = audio
    audio.play()
  }

  const playVoicePreview = async (voice) => {
    let id, url
    if (isActiveVoice(voice)) {
      id =
        activeVoice.voice.style === 'default'
          ? activeVoice.voice.id
          : `${activeVoice.voice.id}:${activeVoice.voice.style}`
      url =
        voice.voiceProvider === 'azure'
          ? voice.stylePreview?.[activeVoice.voice.style] ?? voice.url
          : voice.url || voice.playedTags?.find((t) => t.id === activeVoice.voice.id)?.url
    } else {
      id = voice.voice || voice.playedTags[0].id
      url = voice.url || voice.playedTags?.[0].url
    }
    if (voicePreviewAudio.current) {
      if (id === voicePreviewPlayer.id) {
        setVoicePreviewPlayer({ id, state: 'playing' })
        voicePreviewAudio.current.play()
        return
      } else {
        voicePreviewAudio.current.pause()
        createVoicePreviewAudio(id, url, voice.voiceProvider)
      }
    } else {
      createVoicePreviewAudio(id, url, voice.voiceProvider)
    }
  }

  const pauseVoicePreview = () => {
    if (voicePreviewAudio.current) voicePreviewAudio.current.pause()
    setVoicePreviewPlayer({ ...voicePreviewPlayer, state: 'idle' })
  }

  const applyVoiceToAllSlides = async () => {
    updateVideo((video) => ({
      slides: video.slides.map((s) => {
        const { objects, requestUpdateCanvas } = resetTimeOfObjectsWithMarkers(s.canvas.objects)
        if (s.avatar.gender === data.avatar.gender) {
          return {
            ...s,
            language: activeVoice.language,
            voice:
              activeVoice.voice.style === 'default'
                ? activeVoice.voice.id
                : `${activeVoice.voice.id}:${activeVoice.voice.style}`,
            voiceProvider: activeVoice.voice.voiceProvider,
            status: activeVoice.voice.id !== s.voice ? 'edited' : s.status,
            canvas: { ...s.canvas, objects },
            updateCanvas: requestUpdateCanvas,
            duration: null,
          }
        } else return s
      }),
    }))
    setVisibleVoiceModal(false)
  }

  const applyVoice = () => {
    const { objects, requestUpdateCanvas } = resetTimeOfObjectsWithMarkers(data.canvas.objects)
    updateSlide({
      language: activeVoice.language,
      voice:
        activeVoice.voice.style === 'default'
          ? activeVoice.voice.id
          : `${activeVoice.voice.id}:${activeVoice.voice.style}`,
      status: activeVoice.voice.id !== data.voice ? 'edited' : data.status,
      approxDuration: getApproxDuration(data),

      voiceProvider: activeVoice.voice.voiceProvider,
      canvas: { ...data.canvas, objects },
      updateCanvas: requestUpdateCanvas,
      duration: null,
    })
    setVisibleVoiceModal(false)
  }

  const afterCloseModal = () => {
    setSelectedTags([])
    setPremiumVoicesVisible(true)
    if (voicePreviewPlayer.state === 'playing') {
      setVoicePreviewPlayer({ ...voicePreviewPlayer, state: 'idle' })
      pauseVoicePreview()
    }
    voicePreviewAudio.current = null
  }

  const closeModal = () => {
    setVisibleVoiceModal(false)
  }

  const onLanguageSelect = (v) => {
    const defaultLangVoice = voices.find((voice) => voice.name === v)[data.avatar.gender][0]
    setActiveVoice({
      language: v,
      voice: { id: defaultLangVoice.voice, ...defaultLangVoice, style: 'default' },
    })
    setSelectedTags([])
  }

  const laguageFilterOptions = (input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0

  const onSwitchClick = (checked) => {
    setPremiumVoicesVisible(checked)
  }

  return {
    user,
    closeModal,
    voicesList,
    voicesTags,
    applyVoice,
    activeVoice,
    selectedTags,
    isActiveVoice,
    onSwitchClick,
    setActiveVoice,
    afterCloseModal,
    setSelectedTags,
    onLanguageSelect,
    playVoicePreview,
    pauseVoicePreview,
    isPremiumAvailable,
    voicePreviewPlayer,
    premiumVoicesVisible,
    laguageFilterOptions,
    applyVoiceToAllSlides,
    setVoicePreviewPlayer,
  }
}
