import React, { useEffect } from 'react'
import axios from 'axios'
import { Spin, Result, Button, Space } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { apiUrl } from '../../utils/config'
import { getAuthHeaders } from '../../utils/api'
import { useLocation } from 'react-router'
import { useStore } from '../../store'
import thinkificLogo from '../../assets/images/thinkific-dark.svg'
import { useElaiNotification } from '../../hooks/useElaiNotification'

export default () => {
  const notification = useElaiNotification()
  const { search } = useLocation()
  const authStore = useStore((stores) => stores.authStore)
  const navigate = useNavigate()
  const params = new URLSearchParams(search)
  const subdomain = params.get('subdomain')
  const code = params.get('code')

  const authThinkific = async () => {
    try {
      const { data } = await axios.get(apiUrl + '/auth/auth-social' + search, {
        headers: authStore.user ? getAuthHeaders() : {},
      })
      notification.success({
        message: data.message,
      })
      await authStore.login(data)
      navigate('/')
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message, duration: false })
    }
  }

  useEffect(() => {
    if (code) {
      if (authStore.user) authThinkific()
    } else {
      const url = `https://${subdomain}.thinkific.com/oauth2/authorize?response_type=code%20id_token&client_id=${process.env.REACT_APP_THINKIFIC_KEY}&redirect_uri=${window.location.origin}/install&scope=openid%20profile%20email`
      window.location.href = url
    }
  }, [])

  if (!code) return <Spin size="large" />

  return (
    <Result
      style={{ maxWidth: 450, margin: '0 auto' }}
      title="Complete installation"
      icon={<img src={thinkificLogo} />}
      subTitle={
        <>
          <p>
            If you already have an account with Elai, you can link it to your Thinkific account, otherwise just create a
            new one.
          </p>
          <p>If you already installed Elai app with Thinkific, just click "Create new account" to relogin.</p>
        </>
      }
      extra={
        <Space>
          <Button onClick={authThinkific} type="primary">
            Create new account
          </Button>
          <Link to={`/login?redirectUrl=${encodeURIComponent(`/install${search}`)}`}>
            <Button>Link with existing</Button>
          </Link>
        </Space>
      }
    />
  )
}
