import axios from 'axios'
import { apiUrl } from './config'
import { clearSession } from '../store/authStore'
import { elaiNotification as notification } from './helpers'

let isTokenUpdating = false

const getToken = () => localStorage.getItem('token')

const updateToken = async () => {
  let result = null

  if (!isTokenUpdating) {
    isTokenUpdating = true
    const client = axios.create({ baseURL: apiUrl })
    const refreshToken = localStorage.getItem('refreshToken')
    const userId = localStorage.getItem('userId')
    const headers = { 'Content-Type': 'application/json' }
    try {
      const response = await client.post(`/auth/refresh`, { refreshToken, userId }, { headers })
      localStorage.setItem('refreshToken', response.data.refreshToken)
      localStorage.setItem('token', response.data.accessToken)
      result = response.data.accessToken
    } catch (error) {
      notification.error({ message: error.message, duration: null, key: 'api-error' })
      clearSession()
      window.location.reload()
    }
    isTokenUpdating = false
  } else {
    while (isTokenUpdating) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((r) => setTimeout(r, 500))
    }
    return getToken()
  }
  return result
}

const request = async ({
  method,
  url,
  data,
  headers,
  params,
  allowedStatus,
  errorToDescriptionConverter,
  customErrorHandler,
  responseType,
  onBeforeHandleError,
  onUploadProgress,
  withCredentials,
  passthroughResponse, // return whole response object instead of data
}) => {
  const client = axios.create({ baseURL: apiUrl })
  let token = getToken()
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  }
  const subAccountId = localStorage.getItem('subAccountId')
  if (subAccountId) headers['sub-account-id'] = subAccountId

  const adminUserId = localStorage.getItem('adminUserId')
  if (adminUserId) {
    headers['admin-user-id'] = adminUserId
    headers['admin-account-id'] = localStorage.getItem('adminAccountId')
  }

  let response
  try {
    response = await client({
      method,
      url,
      data,
      params,
      headers,
      responseType,
      onUploadProgress,
      withCredentials,
    })
    if (passthroughResponse) return response
    if (response.status === 206) return { status: 206 } // means that request was interrupted by timeout from backend
    return response.data
  } catch (error) {
    if (error.response?.status === 401) {
      token = await updateToken()
      if (token) {
        headers.Authorization = `Bearer ${token}`
        response = await client({ method, url, data, params, headers, withCredentials })
        return response.data
      }
      localStorage.removeItem('token')
      document.location.href = '/login'
      return null
    }
    if (customErrorHandler && typeof customErrorHandler === 'function') {
      if (onBeforeHandleError) await onBeforeHandleError(error)
      customErrorHandler(error)
      return false
    }

    if (axios.isCancel(error)) {
      if (onBeforeHandleError) {
        error.aborted = true
        await onBeforeHandleError(error)
      }
      return false
    }

    if (allowedStatus) {
      if (error.response && allowedStatus === error.response.status) return false
      else if (allowedStatus === 'network-error' && !error.response) return false
    }

    let description = ''
    let message = error.toString()
    if (error.response) {
      if (onBeforeHandleError) await onBeforeHandleError(error.response?.data, error.response?.status)

      if (error.response?.data?.isCustomNotification) {
        message = error.response.data.message
        description = errorToDescriptionConverter ? (
          errorToDescriptionConverter(error.response.data.context)
        ) : (
          <pre>{JSON.stringify({ ...error.response.data.context }, null, 2)}</pre>
        )
      } else {
        switch (error.response.status) {
          case 400:
            message = 'Invalid data'
            break
          case 404:
            message = 'Page not found'
            break
          case 429:
            message = 'Too many requests'
            break
          case 500:
            message = 'Error occured'
            break
          default:
            message = `Server error ${error.response.status}`
            break
        }
        if (error.response.data.message) {
          description += error.response.data.message
        }
        if (error.response.data.length && error.response.data[0].message) {
          description += error.response.data[0].message
        }
      }
    }

    notification.destroy('api-error')
    setTimeout(() => {
      // it should be called on next event-loop iteration, because antd&react don't update in time corresponding internal state
      notification.error({ message, description, duration: null, key: 'api-error' })
    }, 100)
    return false
  }
}

const getAuthHeaders = () => {
  const headers = { Authorization: `Bearer ${getToken()}` }
  const subAccountId = localStorage.getItem('subAccountId')
  if (subAccountId) headers['sub-account-id'] = subAccountId
  const adminUserId = localStorage.getItem('adminUserId')
  if (adminUserId) {
    headers['admin-user-id'] = adminUserId
    headers['admin-account-id'] = localStorage.getItem('adminAccountId')
  }
  return headers
}

const uploadDirectlyToS3 = async (props) => {
  const { file, onSuccess, onError, onProgress, data = {} } = props
  const { type: mimetype } = file
  const name = file.name
  const { signedUrl, headers } = await request({
    method: 'GET',
    url: 'uploads/signedUrl',
    params: { name, mimetype },
  })

  const onUploadProgress = (event) => {
    const percent = Math.round((100 * event.loaded) / event.total)
    onProgress?.({ percent })
  }
  const ok = await axios
    .put(signedUrl, file.base64 ? file.data : file, {
      headers: { 'Content-Type': mimetype, ...headers },
      onUploadProgress,
    })
    .then(() => true)
    .catch(() => false)
  if (!ok) return onError('Upload failed')

  if (file.base64) data.base64 = true

  const upload = await request({
    method: 'POST',
    url: 'uploads',
    data: { signedUrl, ...data },
  })
  if (upload) onSuccess?.(upload)
}

const customUploadRequest = async ({ action, method, file, filename, onSuccess, onError, onProgress }) => {
  const formData = new FormData()
  formData.append(filename, file)
  const onUploadProgress = (event) => {
    const percent = Math.round((100 * event.loaded) / event.total)
    onProgress?.({ percent })
  }
  const upload = await request({
    method,
    url: action,
    data: formData,
    onBeforeHandleError: onError,
    onUploadProgress,
  })
  if (upload) onSuccess?.(upload)
}

export { request, getAuthHeaders, uploadDirectlyToS3, customUploadRequest }
