import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Layout, Spin, Button, Row, Col } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { CourseBreadcrumb } from '../../components/courseBreadcrumb'
import { request } from '../../../../utils/api'
import { dynamicTitle, playerUrl } from '../../../../utils/config'
import { formatMinutes } from '../../../../utils/helpers'
import { useStore } from '../../../../store'
import materials_image from '../../../../assets/images/academy/materials.png'
import Icon from '../../../../components/Icon'
import '../../academy.less'
import './coursePreview.less'

const CoursePreview = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const materialsContainer = useRef(null)
  const user = useStore((stores) => stores.authStore.user)
  const [course, setCourse] = useState(null)
  const [loadingCourse, setLoadingCourse] = useState(false)

  useEffect(() => {
    const fetchCourse = async () => {
      setLoadingCourse(true)
      const courseRes = await request({ method: 'get', url: `public/course/${id}` })
      if (courseRes) setCourse(courseRes)
      setLoadingCourse(false)
    }
    fetchCourse()
  }, [])

  useEffect(() => {
    if (materialsContainer.current && course?.materials) {
      materialsContainer.current.innerHTML = course?.materials
    }
  }, [course?.materials, loadingCourse])

  const onClickStartCourse = async () => {
    if (user) navigate(`/academy/course/${course.id}`)
    else {
      localStorage.setItem('courseToOpen', course.id)
      navigate('/login')
    }
  }

  return (
    <Layout.Content className="elai-academy course-preview">
      {loadingCourse ? (
        <Spin className="spin-centered" />
      ) : (
        course && (
          <div className="academy-content-wrapper">
            <CourseBreadcrumb course={course} />
            <div className="academy-content">
              <div className="overview-section">
                <div className="course-overview-info">
                  <h1>{course.name}</h1>
                  <div className="course-teaser-wrapper mobile">
                    <iframe
                      src={`${playerUrl}/${course.teaserId}`}
                      title={dynamicTitle || 'Elai.io video player'}
                      frameBorder="0"
                      allowFullScreen
                      scrolling="no"
                    ></iframe>
                  </div>
                  <p className="description">{course.description}</p>
                  <div className="course-duration-metrics">
                    <div className="metric-item">
                      <Icon name="open_book" />
                      <span>
                        {course.lessons.length} lesson{course.lessons.length > 1 ? 's' : ''}
                      </span>
                    </div>
                    <div className="metric-item">
                      <Icon name="clock" />
                      <span>{formatMinutes(course.duration, true)}</span>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    icon={<RightOutlined />}
                    className="btn-arrow btn-arrow-xl"
                    size="large"
                    onClick={onClickStartCourse}
                  >
                    Start now
                  </Button>
                </div>
                <div className="course-teaser-wrapper desktop">
                  <iframe
                    src={`${playerUrl}/${course.teaserId}`}
                    title={dynamicTitle || 'Elai.io video player'}
                    frameBorder="0"
                    allowFullScreen
                    scrolling="no"
                  ></iframe>
                </div>
              </div>
              <div className="what-learn-section">
                <h2 className="section-title">What you'll learn:</h2>
                <div className="learn-items-wrapper">
                  <Row gutter={[24, 16]}>
                    {course.whatYouLearn.map((item, index) => (
                      <Col key={`${item}_${index}`} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <div className="learn-item">
                          <Icon name="check" />
                          <p className="item-text">{item}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
              <div className="course-content-section">
                <h2 className="section-title">Course content</h2>
                <div
                  className="lessons-wrapper"
                  style={{ gridTemplateRows: `repeat(${Math.ceil(course.lessons.length / 2)}, auto)` }}
                >
                  {course.lessons.map((lesson, index) => (
                    <div key={lesson._id} className="lesson-item">
                      <span className="lesson-number">
                        {index + 1 < 10 ? '0' : ''}
                        {index + 1}
                      </span>
                      <p className="lesson-name">{lesson.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              {course?.materials && (
                <div className="materials-section">
                  <div className="materials-section-info">
                    <div ref={materialsContainer} className="materials-list-wrapper"></div>
                  </div>
                  <img className="materials-image" src={materials_image} alt="Materials" />
                </div>
              )}
              <Button
                type="primary"
                icon={<RightOutlined />}
                className="btn-arrow btn-arrow-xl btn-start-course"
                size="large"
                onClick={onClickStartCourse}
              >
                Start now
              </Button>
            </div>
          </div>
        )
      )}
    </Layout.Content>
  )
}

export default CoursePreview
