import { useState } from 'react'
import { Modal, Segmented } from 'antd'
import FileUploader from '../../video/sidebar/fileUploader/fileUploader'
import { Pexels } from '../../video/sidebar/elements/elementsTabs/pexels'
import { track } from '../../../utils/analytics'

export const ImageModal = ({
  video,
  editingSlide,
  setEditingSlide,
  openImageIndex,
  setOpenImageIndex,
  updateVideo,
  markSlideStatusAsStory,
}) => {
  const [imagesType, setImagesType] = useState('uploads')

  const chooseImage = (file) => {
    const slideIndex = video.slides.indexOf(editingSlide)
    if (!video.slides[slideIndex].story.images) video.slides[slideIndex].story.images = []
    video.slides[slideIndex].story.images[openImageIndex] = {
      src: file.url,
      thumbnail: file.thumbnail || file.src?.small,
    }
    track('story_change_image', { id: video._id })
    markSlideStatusAsStory(slideIndex)
    updateVideo({ slides: video.slides })
    setOpenImageIndex(false)
    setEditingSlide(false)
  }

  return (
    <Modal
      centered
      open={openImageIndex !== false}
      width={576}
      className="image-modal"
      footer={null}
      onCancel={() => {
        setOpenImageIndex(false)
        setEditingSlide(false)
      }}
    >
      <h2>Add image to the slide</h2>
      <Segmented
        value={imagesType}
        options={[
          { label: 'Uploads', value: 'uploads' },
          { label: 'From stock', value: 'stock' },
        ]}
        onChange={(type) => setImagesType(type)}
      />
      <FileUploader visible={imagesType === 'uploads'} isVideoAllowed={false} onChooseFile={chooseImage} />
      <div style={{ display: imagesType === 'stock' ? 'block' : 'none' }}>
        <Pexels filesType="image" onChooseFile={chooseImage} />
      </div>
    </Modal>
  )
}
