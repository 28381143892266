import { useEffect, useState, useRef } from 'react'

import { queueMicrotask } from '../../utils/helpers'
import { setTimeOnObjectsWithMarkers } from '../../utils/canvas/canvas'
import { checkObjectHasAnimation } from './slide/canvas/animations/animation'
import { useSlideDuration } from '../../hooks/useSlideDuration'

let playingInterval = null

const noop = () => {}

const playSafe = (audio) => audio.play?.().catch(noop)

export const usePlayer = (props) => {
  const {
    video,
    slide,
    audioCache,
    activeSlide,
    updateVideo,
    updateActiveSlide,
    setActiveSidebarTab,
    setActiveSlideWithUrl,
  } = props

  // if status is idle, then player is not activePreview

  const [player, setPlayer] = useState({
    activePreview: false,
    preloading: false,
    duration: 0,

    status: 'idle',
    currentTime: 0,
    canvasReady: false,
    canvasesLocked: false,
    audioReady: false,
    voiceAudio: null,

    hasAnimatedObjects: false,
    objectsVisibilityMode: null, // all, animated
    linkedCanvasObject: null,
  })

  // NOTE: state value might not be always correctly synchronized in closures, such as those used in setInterval
  const manualChangingTime = useRef(false)

  const { getApproxDuration } = useSlideDuration({ slide })

  useEffect(() => {
    if (!video || !video.slides) {
      return
    }

    const duration = video.slides.reduce(
      (duration, slide) => duration + (slide.duration || getApproxDuration(slide) || 0),
      0,
    )

    const hasAnimatedObjects = slide?.canvas?.objects?.some(checkObjectHasAnimation)
    const objectsVisibilityMode = hasAnimatedObjects
      ? localStorage.getItem('objectsVisibilityMode') || 'all'
      : 'animated'
    setPlayer((p) => ({ ...p, duration, hasAnimatedObjects, objectsVisibilityMode }))
  }, [video, slide?.id])

  useEffect(() => {
    if (!slide.canvas) return
    const interactiveSlide = slide.canvas.objects.some((obj) => obj.type === 'question' || obj.meta?.interactivity)
    if (!interactiveSlide && player.status === 'idle' && player.activePreview) {
      onStopPlaying(player.currentTime)
    } else if (player.status === 'switching') {
      if (player.activePreview) {
        if (interactiveSlide) {
          clearInterval(playingInterval)
          setPlayer((p) => ({ ...p, status: 'idle' }))
        } else onStopPlaying()
      } else setPlayer((p) => ({ ...p, status: 'idle', currentTime: 0 }))
    }
  }, [player.status])

  useEffect(() => {
    if (player.activePreview) {
      setPlayer((p) => ({ ...p, status: 'loading' }))
    } else {
      setPlayer((p) => ({ ...p, status: 'idle', currentTime: 0 }))
    }
  }, [player.activePreview])

  useEffect(() => {
    preLoadSlide()
  }, [activeSlide])

  useEffect(() => {
    setPlayer((p) => ({
      ...p,
      status: 'idle',
      currentTime: player.seekTimeForResume || 0,
      audioReady: false,
      voiceAudio: null,
    }))
    if (player.voiceAudio) player.voiceAudio.pause()
  }, [slide.id, slide.voice, slide.speech, slide.voiceType])

  /**
   * Stop audio on audio destroy
   */
  useEffect(
    () => () => {
      if (player.voiceAudio) player.voiceAudio.pause()
    },
    [player.voiceAudio],
  )

  /**
   * PLAYER LOGIC
   * player.currentTime - used in background component
   * status = 'loading' means user requested play and all components are getting ready
   * status = 'playing' means all components are ready and can start playing media
   */
  useEffect(() => {
    const { status, audioReady, canvasReady, voiceAudio } = player
    if (status === 'idle') {
      if (voiceAudio) voiceAudio.pause()
      clearInterval(playingInterval)
    } else if (status === 'loading' && audioReady && canvasReady && voiceAudio) {
      clearInterval(playingInterval)
      setPlayer((p) => ({ ...p, status: 'playing' }))
      playSafe(voiceAudio)
      playingInterval = setInterval(() => {
        if (player.voiceAudio?.ended) {
          // if audio ended, then we should ignoring further changes
          return
        }
        if (manualChangingTime.current) {
          manualChangingTime.current = false
          return
        }
        setPlayer((p) => ({
          ...p,
          status: 'playing',
          currentTime: voiceAudio.currentTime,
        }))
      }, 40)
    }
  }, [player.status, player.audioReady, player.canvasReady, player.voiceAudio])

  /**
   * PLAYER LOGIC
   * We should start player over on changing slide
   */
  useEffect(() => {
    clearInterval(playingInterval)
    setPlayer((p) => ({ ...p, status: 'idle', currentTime: player.seekTimeForResume || 0, audioReady: false }))
  }, [slide.id])

  /**
   * When idle - check for player time changed manually and update audio
   */
  useEffect(() => {
    if (player.status === 'idle' && player.voiceAudio) player.voiceAudio.currentTime = player.currentTime
  }, [player.currentTime])

  const preLoadSlide = async () => {
    if (!video) return
    const currentSlide = video.slides[activeSlide]
    if (!currentSlide?.canvas) return
    const interactiveSlide = currentSlide.canvas.objects.find(
      (obj) => obj.meta?.interactivity || obj.type === 'question',
    )
    const nextSlide = currentSlide.interactivity?.nextSlide
    if (!interactiveSlide && player.activePreview && (activeSlide + 1 < video.slides.length || nextSlide)) {
      const slideIndex = nextSlide ? video.slides.findIndex((slide) => slide.id === nextSlide) : activeSlide + 1
      const slide = video.slides[slideIndex]
      const speechData = await audioCache.preloadAudioForSlide(slide)
      if (speechData?.markers) updateSlideWithMarkers(slide, slideIndex, speechData.markers)
    }
  }

  const playVideo = async () => {
    if (player.activePreview) {
      stopVideo()
      // for time to update all related effects
      await new Promise((resolve) => queueMicrotask(resolve))
    }

    setPlayer((p) => ({ ...p, preloading: true }))

    const slide = video.slides[0]
    const speechData = await audioCache.preloadAudioForSlide(slide)
    if (speechData?.markers) updateSlideWithMarkers(slide, 0, speechData.markers)

    setPlayer((p) => ({ ...p, preloading: false }))

    updateActiveSlide(0)
    setActiveSidebarTab('music')
    setPlayer((p) => ({ ...p, activePreview: true, currentTime: 0, status: 'idle' }))

    if (video.slides.length > 1) {
      const slide = video.slides[1]
      const speechData = await audioCache.preloadAudioForSlide(slide)
      if (speechData?.markers) updateSlideWithMarkers(slide, 1, speechData.markers)
    }
  }

  const stopVideo = () => {
    setPlayer((p) => ({ ...p, activePreview: false }))
    clearInterval(playingInterval)
  }

  const onStopPlaying = (time) => {
    if (!player.activePreview || (typeof time !== 'undefined' && time !== video.slides[activeSlide].duration)) {
      return
    }

    const nextSlideId = slide.interactivity?.nextSlide
    if (activeSlide + 1 < video.slides.length || nextSlideId) {
      const nextSlide = nextSlideId ? video.slides.findIndex((slide) => slide.id === nextSlideId) : activeSlide + 1
      setActiveSlideWithUrl(nextSlide)
    } else {
      stopVideo()
    }
  }

  const togglePlay = () => {
    setPlayer((p) => ({ ...p, status: p.status === 'idle' ? 'loading' : 'idle' }))

    if (!player.voiceAudio) return

    if (player.voiceAudio.paused) {
      playSafe(player)
    } else {
      player.voiceAudio.pause()
      stopVideo()
    }
  }

  const updateSlideWithMarkers = (slide, slideIndex, markers) => {
    const { slides } = video
    const objects = setTimeOnObjectsWithMarkers(slide.canvas.objects, markers)
    slides[slideIndex] = { ...slide, canvas: { ...slide.canvas, objects } }
    updateVideo({ slides }, { ignoreHistory: true })
  }

  const playNextSlide = (slideId) => {
    const slideIndex = video.slides.findIndex((slide) => slide.id === slideId)
    const slide = slideIndex > -1 ? slideIndex : activeSlide + 1
    if (slide < video.slides.length) {
      setActiveSlideWithUrl(slide)
    } else {
      stopVideo()
    }
  }

  return {
    player,
    setPlayer,
    playVideo,
    stopVideo,
    manualChangingTime,
    togglePlay,
    playNextSlide,
  }
}
