import Scrollbars from 'react-custom-scrollbars'
import { Modal, Row, Col, Tag, Radio, Select, Empty, Card, Button } from 'antd'

import Icon from '../../../components/Icon'
import SlidesCarousel from '../../../components/SlidesCarousel'
import PageHeader from '../../../components/PageHeader/pageHeader'

import { useCreateTemplateState } from './useCreateTemplateState'

import { STYLE, searchOutlinedIcon } from './constants'
import { dynamicTitle, playerUrl } from '../../../utils/config'
import create_template_icon from '../../../assets/images/video_creation_modal/create-template-icon.svg'
import blank_icon from '../../../assets/images/video_creation_modal/neyson_thumbnail.jpg'
import { defaultBlankVideoName } from '../../../utils/constants'
import useScrollToBottom from '../../../hooks/useScrollToBottom'

const { Meta } = Card

export const CreateTemplateModal = (props) => {
  const { templates, onTemplateCreated, isNewTemplateModalOpen, setIsNewTemplateModalOpen } = props

  const {
    templateTags,
    onTagsCHange,
    handleCancel,
    handleOnBack,
    openedTemplate,
    onFilterChange,
    templatesFilters,
    templatesToShow,
    setOpenedTemplate,
    handleCreateClick,
    isCreatingTemplate,
    handleScrollToBottom,
  } = useCreateTemplateState({ templates, onTemplateCreated, isNewTemplateModalOpen, setIsNewTemplateModalOpen })

  const { scrollRef, saveScrollPosition } = useScrollToBottom(
    handleScrollToBottom,
    !!openedTemplate,
    (el) => el?.parentNode,
  )

  const openTemplate = (template) => {
    saveScrollPosition()
    setOpenedTemplate(template)
  }

  // it's required for correct work of ref for scroll to bottom
  if (!isNewTemplateModalOpen) return null

  return (
    <Modal
      destroyOnClose
      open={isNewTemplateModalOpen}
      width={1050}
      className="video-creation-modal templates-creation-modal"
      centered
      title={
        <div className="modal-header">
          <div style={STYLE.header}>
            <img src={create_template_icon} style={STYLE.marginRight16} width="24" alt="template creation icon" />{' '}
            <span>Create your own template</span>
          </div>
        </div>
      }
      footer={
        openedTemplate && (
          <Button type="primary" style={STYLE.marginRight16} loading={isCreatingTemplate} onClick={handleCreateClick}>
            Create template
          </Button>
        )
      }
      bodyStyle={STYLE.bodyStyle}
      onCancel={handleCancel}
    >
      <div style={{ display: openedTemplate ? 'none' : 'block' }}>
        <h2 className="title">Start from scratch or use existing design</h2>
        <Row gutter={[8, 16]} style={STYLE.marginBottom16}>
          <Col xs={24} sm={24} md={24} lg={14}>
            <Radio.Group className="format-radio-btns" value={templatesFilters.format} onChange={onFilterChange}>
              <Radio.Button
                value="all"
                className={`format-radio-btn ${templatesFilters.format === 'all' ? 'active' : ''}`}
              >
                <Icon name="page_collection" />
                <span>All</span>
              </Radio.Button>
              <Radio.Button
                value="16_9"
                className={`format-radio-btn ${templatesFilters.format === '16_9' ? 'active' : ''}`}
              >
                <Icon name="monitor" />
                <span>Horizontal</span>
              </Radio.Button>
              <Radio.Button
                value="9_16"
                className={`format-radio-btn ${templatesFilters.format === '9_16' ? 'active' : ''}`}
              >
                <Icon name="mobile" />
                <span>Vertical</span>
              </Radio.Button>
              <Radio.Button
                value="1_1"
                className={`format-radio-btn ${templatesFilters.format === '1_1' ? 'active' : ''}`}
              >
                <Icon name="focus" />
                <span>Square</span>
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col xs={24} sm={24} md={12} lg={10}>
            <Select
              mode="multiple"
              style={STYLE.width100}
              placeholder="Search by tags"
              suffixIcon={searchOutlinedIcon}
              showArrow="true"
              onChange={onTagsCHange}
            >
              {templateTags.map((t) => (
                <Select.Option key={t}>{t}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Scrollbars className="template-scrollbar">
          <Row gutter={[16, 16]} className="templates-container" ref={scrollRef}>
            {templatesToShow?.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={STYLE.emptyImage} />
            ) : (
              templatesToShow.map((v, index) => (
                <Col key={v._id} style={STYLE.flex} xs={12} sm={8} md={6}>
                  <Card
                    className={`template-card ${v.name === defaultBlankVideoName ? 'blank' : ''} ${
                      v.data?.format === '9_16' ? 'vertical' : ''
                    } ${v.data?.format === '1_1' ? 'square' : ''}`}
                    bodyStyle={STYLE.padding0}
                    onClick={() => openTemplate(v)}
                  >
                    <Meta title={<span style={STYLE.title}>{v.name}</span>} className="card-head" />
                    {v.template ? (
                      <SlidesCarousel
                        collection={{
                          ...v,
                          items: v.slides.map((slide) => ({ ...slide, src: slide.screenshot })),
                        }}
                        index={index}
                      />
                    ) : (
                      <img src={blank_icon} alt="create video from scratch icon" />
                    )}
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Scrollbars>
      </div>
      {openedTemplate && (
        <>
          <PageHeader style={STYLE.pageHeader} onBack={handleOnBack} title={openedTemplate.name} />
          <Scrollbars className={`template-scrollbar opened-template`}>
            <Row justify="start" gutter={8}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <iframe
                  src={`${playerUrl}/${openedTemplate._id}${
                    localStorage.getItem('token') ? `?token=${localStorage.getItem('token')}` : ''
                  }`}
                  title={dynamicTitle || 'Elai.io video player'}
                  className="template-media player"
                  frameBorder="0"
                  allowFullScreen
                  scrolling="no"
                ></iframe>
                <div className="template-drawer-tags">
                  {openedTemplate.template?.tags?.map((tag) => (
                    <Tag key={tag} style={STYLE.marginBottom5}>
                      {tag}
                    </Tag>
                  ))}
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <Row gutter={5}>
                  {openedTemplate.slides.map((s) => (
                    <Col
                      xs={openedTemplate?.data?.format === '9_16' ? 12 : 12}
                      sm={openedTemplate?.data?.format === '9_16' ? 12 : 12}
                      md={openedTemplate?.data?.format === '9_16' ? 8 : 12}
                      lg={openedTemplate?.data?.format === '9_16' ? 6 : 12}
                      key={s.id}
                    >
                      <img src={s.screenshot} className="template-media" />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Scrollbars>
        </>
      )}
    </Modal>
  )
}
