import { useEffect, useMemo, useState } from 'react'
import { Layout, Table, Spin, Input, Space, Button, Tag, Modal, Form, Select, Alert } from 'antd'
import { SearchOutlined, EditOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons'
import { useStore } from '../../store'
import { request } from '../../utils/api'
import { voiceStatuses } from './constants'
import Icon from '../../components/Icon'
import PageHeader from '../../components/PageHeader/pageHeader'
import { useElaiNotification } from '../../hooks/useElaiNotification'

const Voices = () => {
  const notification = useElaiNotification()
  const [voices, setVoices] = useState()
  const [modalEditVoice, setModalEditVoice] = useState()
  const [elevenLabVoices, setElevenLabVoices] = useState()
  const [loadingVoice, setLoadingVoice] = useState(false)
  const authStore = useStore((stores) => stores.authStore)
  const [voiceForm] = Form.useForm()

  const isCreateVoice = modalEditVoice === true

  const fetchVoices = async () => {
    const voicesData = await request({ method: 'get', url: 'admin/voices' })
    setVoices(voicesData)
  }

  const fetchCustomVoices = async () => {
    const elevenlabs = await request({ method: 'get', url: 'admin/voices/elevenlab-voices' })
    setElevenLabVoices(elevenlabs)
  }

  useEffect(() => {
    fetchVoices()
    fetchCustomVoices()
  }, [])

  const onFinishVoiceForm = async ({ name, id, model, status, accountId, stability, clarity }) => {
    setLoadingVoice(true)
    const data = {
      voiceName: name,
      customVoiceId: `${id}:${model || ''}:${stability || ''}:${clarity || ''}`,
      voiceStatus: status,
      provider: 'elevenlabs',
      stability,
      clarity,
    }

    let res
    if (isCreateVoice) {
      res = await request({
        method: 'post',
        url: 'admin/voices',
        data: { ...data, accountId },
      })
    } else {
      res = await request({
        method: 'patch',
        url: `admin/voices/${modalEditVoice.accountId}/${modalEditVoice._id}`,
        data,
      })
    }

    console.log({ res })

    if (res !== false) {
      fetchVoices()
      voiceForm.resetFields()
      notification.success({ message: 'Saved' })
      setModalEditVoice(false)
    }
    setLoadingVoice(false)
  }

  const onClickEditVoice = (voice) => {
    let voiceId = voice.descript?.id
    let modelId, stability, clarity
    if (voice.elevenlabs?.id) [voiceId, modelId = '', stability = '', clarity = ''] = voice.elevenlabs.id.split(':')
    voiceForm.setFieldsValue({
      name: voice.name,
      id: voiceId,
      status: voice.status,
      model: modelId,
      stability: stability,
      clarity: clarity,
    })
    setModalEditVoice({ ...voice, voiceId, modelId, stability, clarity })
  }

  const onClickCreateVoice = () => {
    voiceForm.setFieldsValue({
      status: 1,
    })
    setModalEditVoice(true)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters()
              confirm()
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toLowerCase().includes(value.toLowerCase()),
  })

  const columns = useMemo(
    () => [
      {
        title: 'Account ID',
        dataIndex: 'accountId',
        ...getColumnSearchProps('accountId'),
        render: (accountId) => {
          return (
            <a
              onClick={async () => {
                await authStore.adminLoginAs({ accountId })
                window.open('/profile', '_blank').focus()
              }}
            >
              {accountId}
            </a>
          )
        },
      },
      {
        title: 'Account name',
        dataIndex: 'accountName',
        ...getColumnSearchProps('accountName'),
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Elevenlabs ID',
        render: (_, record) => record.elevenlabs?.id || record.descript?.id,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: voiceStatuses.map(({ text }, index) => ({ text, value: index })),
        filterMultiple: false,
        onFilter: (status, record) => record.status === status,
        render: (status) => <Tag color={voiceStatuses[status].color}>{voiceStatuses[status].text}</Tag>,
      },

      {
        title: 'Subscription ID',
        dataIndex: 'stripe',
        align: 'center',
        render: (stripe) =>
          stripe.subscriptionId ? <Icon name="check" style={{ color: '#4bb543', fontSize: 22 }} /> : null,
      },
      {
        title: 'Footage link',
        dataIndex: 'data',
        render: (data) => (
          <a href={data.footageLink} target="_blank" rel="noreferrer">
            {data.footageLink}
          </a>
        ),
      },
      {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (d) => (d ? new Date(d).toLocaleString() : null),
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: (_, record) => <Button type="ghost" icon={<EditOutlined />} onClick={() => onClickEditVoice(record)} />,
      },
    ],
    [onClickEditVoice, getColumnSearchProps, authStore.adminLoginAs],
  )

  return (
    <Layout.Content className="admin-content">
      <div className="content">
        <PageHeader
          title="Voices"
          style={{ padding: 0 }}
          extra={[
            <Button icon={<PlusOutlined />} type="primary" key="create-video-btn" onClick={onClickCreateVoice}>
              New Voice
            </Button>,
          ]}
        />
        {voices ? (
          <Table sticky columns={columns} dataSource={voices} rowKey="_id" pagination={false} />
        ) : (
          <Spin size="large" />
        )}
      </div>
      <Modal
        title={isCreateVoice ? 'Create voice' : `${modalEditVoice?.name} - Edit`}
        open={!!modalEditVoice}
        width={500}
        footer={null}
        centered
        onCancel={() => {
          setModalEditVoice(null)
          voiceForm.resetFields()
        }}
      >
        {modalEditVoice && (
          <>
            {modalEditVoice !== true && (
              <>
                <h4>
                  {modalEditVoice.name && (
                    <>
                      <b>name:</b> <span>{modalEditVoice.name}</span>{' '}
                    </>
                  )}
                  <Tag color="blue">
                    <b>status:</b> {modalEditVoice?.status}
                  </Tag>
                </h4>
                <div>
                  <h4 style={{ marginBottom: 10 }}>
                    Footage Link{' '}
                    <Button
                      size="small"
                      type="primary"
                      icon={<CopyOutlined />}
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        navigator.clipboard.writeText(modalEditVoice.data.footageLink)
                      }}
                    ></Button>
                  </h4>
                  <a
                    href={modalEditVoice.data.footageLink}
                    target="_blank"
                    style={{ display: 'block', marginBottom: 16, width: '80%', whiteSpace: 'break-spaces' }}
                    rel="noreferrer"
                  >
                    {modalEditVoice.data.footageLink}
                  </a>
                </div>
              </>
            )}
            <Form form={voiceForm} layout="vertical" onFinish={onFinishVoiceForm}>
              {isCreateVoice && (
                <Form.Item
                  name="accountId"
                  label="Account ID"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: 'Account ID is required',
                    },
                  ]}
                >
                  <Input placeholder="Account ID" />
                </Form.Item>
              )}
              <Form.Item
                name="name"
                label="Name"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
              >
                <Input placeholder="Voice name" />
              </Form.Item>
              <Form.Item name="id" label="Voice ID" validateTrigger="onBlur" rules={[{ required: true }]}>
                {elevenLabVoices?.length ? (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    placeholder="Select custom ID"
                    value={modalEditVoice.voiceId}
                    options={elevenLabVoices?.map((voice) => ({
                      label: voice.name,
                      value: voice.voice_id,
                    }))}
                  />
                ) : (
                  <Alert type="error" message="No voices loaded" />
                )}
              </Form.Item>
              <Form.Item name="model" label="11Labs Model" validateTrigger="onBlur">
                <Select
                  value={modalEditVoice.modelId}
                  options={[
                    { label: 'None', value: '' },
                    { label: 'Eleven Monolingual v1', value: 'eleven_monolingual_v1' },
                    { label: 'Eleven Multilingual v1', value: 'eleven_multilingual_v1' },
                    { label: 'Eleven Multilingual v2', value: 'eleven_multilingual_v2' },
                    { label: 'Eleven Turbo v2.5', value: 'eleven_turbo_v2_5' },
                  ]}
                />
              </Form.Item>
              <Form.Item name="stability" label="11Labs stability" validateTrigger="onBlur">
                <Select
                  value={modalEditVoice.modelId}
                  options={[
                    { label: 'None', value: '' },
                    ...Array(101)
                      .fill(null)
                      .map((_, index) => ({
                        label: `${index}`,
                        value: index,
                      })),
                  ]}
                />
              </Form.Item>
              <Form.Item name="clarity" label="11Labs clarity" validateTrigger="onBlur">
                <Select
                  value={modalEditVoice.modelId}
                  options={[
                    { label: 'None', value: '' },
                    ...Array(101)
                      .fill(null)
                      .map((_, index) => ({
                        label: `${index}`,
                        value: index,
                      })),
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: 'Status is required',
                  },
                ]}
              >
                <Select
                  placeholder="Select status"
                  value={modalEditVoice.status}
                  options={voiceStatuses.map(({ text }, index) => ({ label: text, value: index }))}
                />
              </Form.Item>
              <Form.Item>
                <Button loading={loadingVoice} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </Layout.Content>
  )
}

export default Voices
