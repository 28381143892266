import { Row, Upload, Collapse, Tooltip } from 'antd'
import { FontSizeOutlined, DeleteOutlined } from '@ant-design/icons'

import { useStore } from '../../../../../../store'
import { apiUrl } from '../../../../../../utils/config'
import { STYLE } from '../constants'
import { customUploadRequest } from '../../../../../../utils/api'
import { textTypes } from '../../../../../../utils/canvas/canvas'
import { useElaiNotification } from '../../../../../../hooks/useElaiNotification'
import { getFontFamilyWithFallback, mapSubfamilyToStyle } from '../../../../../../utils/fabric/fontUtils'

const { Panel } = Collapse
const { Dragger } = Upload

export const CustomFontPanel = (props) => {
  const { fetchFonts, setActiveObjectModifier, customFontsForSelect, updateVideo, deleteFont } = props
  const notification = useElaiNotification()

  const userPlan = useStore((stores) => stores.authStore.user.account.plan)
  const isUploadAvailable = userPlan !== 'basic'

  const onDraggerChange = async ({ file, fileList }) => {
    if (!fileList.every((file) => file.status === 'done' || file.status === 'error')) return

    const uploadedFonts = fileList.filter((file) => file.status === 'done')
    if (uploadedFonts.length === 0) return

    notification.success({
      message: `New ${uploadedFonts.length > 1 ? 'fonts were' : 'font was'} uploaded successfully.`,
    })

    const targetFont = uploadedFonts.shift()
    await fetchFonts()
    const { subfamily } = targetFont.response
    setActiveObjectModifier({
      change: {
        fontFamily: getFontFamilyWithFallback(targetFont.response?.name || targetFont.name),
        ...mapSubfamilyToStyle(subfamily),
      },
    })
  }

  return (
    <Row style={STYLE.marginBottom10}>
      <Collapse ghost className="custom-font-collapse">
        <Panel
          collapsible={isUploadAvailable ? 'header' : 'disabled'}
          showArrow={false}
          header={
            <Tooltip
              overlayStyle={{ visibility: isUploadAvailable ? 'hidden' : 'visible' }}
              title=" Upgrade to Advanced or Enterprise plan to add your own custom fonts"
            >
              Manage custom fonts
            </Tooltip>
          }
        >
          <Dragger
            name="file"
            action={`${apiUrl}/fonts/`}
            accept=".ttf"
            multiple
            customRequest={customUploadRequest}
            onChange={onDraggerChange}
          >
            <p className="ant-upload-drag-icon">
              <FontSizeOutlined />
            </p>
            <p className="ant-upload-text">Click or drag a TTF file to this area to upload</p>
          </Dragger>
          <div className="custom-fonts-list">
            {customFontsForSelect.map((font) => {
              return (
                <div
                  key={font._id}
                  className="custom-font"
                  style={{ fontFamily: `"${font.name}"`, ...mapSubfamilyToStyle(font.subfamily) }}
                >
                  <DeleteOutlined
                    className="custom-font-delete"
                    title="Click to delete"
                    onClick={() => {
                      deleteFont(font._id)
                      updateVideo(
                        ({ slides }) => ({
                          slides: slides.map((slide) => {
                            slide.canvas.objects = slide.canvas.objects.map((object) => {
                              if (textTypes.includes(object.type) && object.fontFamily === font.name) {
                                return {
                                  ...object,
                                  fontFamily: getFontFamilyWithFallback('Georgia'),
                                }
                              }
                              return object
                            })
                            return slide
                          }),
                        }),
                        { updateActiveCanvas: true },
                      )
                    }}
                  />{' '}
                  {font.displayName}
                </div>
              )
            })}
          </div>
        </Panel>
      </Collapse>
    </Row>
  )
}
