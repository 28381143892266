/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import axios from 'axios'
import { Form, Input, Button, Card, Tooltip } from 'antd'
import { LockOutlined, MailOutlined, RightOutlined } from '@ant-design/icons'
import { apiUrl } from '../../utils/config'
import { useLocation } from 'react-router'
import { useStore } from '../../store'
import { Link, useNavigate } from 'react-router-dom'
import Turnstile from 'react-turnstile'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { getDefaultRedirectUrl } from '../../utils/navigation'

const ForgotPassword = () => {
  const notification = useElaiNotification()
  const [form] = Form.useForm()
  const { search } = useLocation()
  const navigate = useNavigate()
  const authStore = useStore((stores) => stores.authStore)
  const [turnstileToken, setTurnstileToken] = useState()
  const [loadingForm, setLoadingForm] = useState(false)
  const [sendFormDataAttempts, setSendFormDataAttempts] = useState(0)

  const params = new URLSearchParams(search)
  const code = params.get('code')
  const isInvitation = params.has('invitation')

  const onFinish = async (formData) => {
    try {
      setLoadingForm(true)
      if (code) {
        const { data } = await axios.post(`${apiUrl}/auth/reset-password`, {
          email: params.get('email'),
          code,
          password: formData.password,
          isInvitation,
          turnstileToken,
        })
        notification.success({
          message: isInvitation
            ? 'You are registered! Now, you can create your first AI video.'
            : 'Your password was successfully updated.',
        })
        await authStore.login(data)
        setLoadingForm(false)
        navigate(getDefaultRedirectUrl(data))
        return
      }

      await axios.post(`${apiUrl}/auth/reset-password`, {
        email: formData.email,
        turnstileToken,
      })
      form.resetFields()
      notification.success({
        message: 'Your request was received. Please check your email and follow the instructions.',
      })
      setLoadingForm(false)
      setSendFormDataAttempts((attempts) => attempts + 1)
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message })
      setLoadingForm(false)
      setSendFormDataAttempts((attempts) => attempts + 1)
    }
  }

  return (
    <Card className="login">
      <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false}>
        {code ? (
          <>
            <h2>Set up a new password</h2>
            <Form.Item
              name="password"
              label="New Password"
              validateTrigger="onBlur"
              rules={[{ required: true, min: 8, pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/ }]}
              help={
                <p className="form-password-hint">
                  Password should be at least 8 characters long, must contain a lowercase letter, an uppercase letter,
                  and a number
                </p>
              }
            >
              <Input.Password prefix={<LockOutlined />} placeholder="New Password" size="large" />
            </Form.Item>
            <Form.Item key={sendFormDataAttempts} noStyle>
              <Turnstile
                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                autoResetOnExpire={true}
                action="set-password"
                appearance="interaction-only"
                className="turnstile-captcha"
                onVerify={(token) => setTurnstileToken(token)}
                onError={() => setSendFormDataAttempts((attempts) => attempts + 1)}
              />
            </Form.Item>
            <Form.Item>
              <Tooltip
                title="We are applying captcha. Please wait a second…"
                overlayStyle={{
                  visibility: turnstileToken ? 'hidden' : 'visible',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={!turnstileToken || loadingForm}
                  icon={<RightOutlined />}
                  className="btn-login btn-arrow"
                  size="large"
                >
                  Save Password
                </Button>
              </Tooltip>
            </Form.Item>
          </>
        ) : (
          <>
            <h2>Forgot your password?</h2>
            <Form.Item>
              No worries. Enter the email address associated with your account and we’ll send you a secure reset link.
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              validateTrigger="onBlur"
              rules={[{ required: true, type: 'email', message: 'Please, enter your email' }]}
            >
              <Input prefix={<MailOutlined />} placeholder="you@company.com" size="large" />
            </Form.Item>
            <Form.Item key={sendFormDataAttempts} noStyle>
              <Turnstile
                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                autoResetOnExpire={true}
                action="email-reset-password"
                appearance="interaction-only"
                className="turnstile-captcha"
                onVerify={(token) => setTurnstileToken(token)}
                onError={() => setSendFormDataAttempts((attempts) => attempts + 1)}
              />
            </Form.Item>
            <Form.Item>
              <Tooltip
                title="We are applying captcha. Please wait a second…"
                overlayStyle={{
                  visibility: turnstileToken ? 'hidden' : 'visible',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={!turnstileToken || loadingForm}
                  icon={<RightOutlined />}
                  className="btn-login btn-arrow"
                  size="large"
                >
                  Reset Password
                </Button>
              </Tooltip>
            </Form.Item>
            <Form.Item className="login-link">
              <Link to="/login">Back to Sign in</Link>
            </Form.Item>
          </>
        )}
      </Form>
    </Card>
  )
}

export default ForgotPassword
