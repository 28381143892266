import { textTypes } from '../../../utils/canvas/canvas'

const getLongColor = (color) => {
  color = color.replace('#', '')
  if (color.length === 3) color = [...color].reduce((acc, c) => `${acc}${c.repeat(2)}`, '')
  return color
}

const getLuma = (color) => {
  const rgb = hexToRGB(color)
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
}

const hexToRGB = (color) => {
  color = normalizeColor(color).replace('#', '')
  const rgb = []
  for (let i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16)
  return rgb
}

const rgbToHex = (r, g, b) => '#' + [r, g, b].map((c) => c.toString(16).padStart(2, '0')).join('')

const rgbStringToHex = (color) => {
  return rgbToHex(
    ...color
      .match(/\((.+?)\)/)[1]
      .split(',')
      .map((c) => Number(c)),
  )
}

const rgbaStringToHex = (color) => {
  return rgbToHex(
    ...color
      .match(/\((.+?)\)/)[1]
      .split(',')
      .slice(0, 3)
      .map((c) => Number(c)),
  )
}

/**
 * for colors like: black, orange, blue etc.
 */
const standardizeColor = (humanReadableColor) => {
  const ctx = document.createElement('canvas').getContext('2d')
  ctx.fillStyle = humanReadableColor
  return ctx.fillStyle
}

const normalizeColor = (color) => {
  if (!color || typeof color !== 'string') color = '#000'
  if (color.startsWith('rgba')) {
    //transparent color only for shapes
    if (color === 'rgba(0, 0, 0, 0)') return 'rgba(0, 0, 0, 0)'
    return rgbaStringToHex(color)
  }
  if (color.startsWith('rgb')) return rgbStringToHex(color)
  if (color.startsWith('#')) {
    if (color.length === 4) return `#${getLongColor(color.toLowerCase())}`
    else return color.toLowerCase()
  }
  return standardizeColor(color)
}

const grabVideoColors = (video, normalize = false) => {
  const colors = new Set()
  video.slides.forEach((slide) => {
    colors.add(normalize ? normalizeColor(slide.canvas.background) : slide.canvas.background)
    slide.canvas.objects.forEach((obj) => {
      if (obj.type === 'avatar' && obj.avatarType !== 'circle') return
      if (obj.fill?.type === 'linear')
        obj.fill.colorStops.forEach((cs) => colors.add(normalize ? normalizeColor(cs.color) : cs.color))
      else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (style.fill) colors.add(normalize ? normalizeColor(style.fill) : style.fill)
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) {
              const fill = obj.styles[row][col].fill
              colors.add(normalize ? normalizeColor(fill) : fill)
            }
          }
        }
      } else if (obj.type === 'group') {
        obj.objects.forEach((path) => {
          if (path.fill?.type === 'linear')
            path.fill.colorStops.forEach((cs) => colors.add(normalize ? normalizeColor(cs.color) : cs.color))
          else colors.add(normalize ? normalizeColor(path.fill) : path.fill)
        })
      } else if (obj.fill && typeof obj.fill === 'string') colors.add(normalize ? normalizeColor(obj.fill) : obj.fill)
    })
  })
  if (colors.has('rgba(0, 0, 0, 0)')) colors.delete('rgba(0, 0, 0, 0)')
  return colors
}

const checkAndSetSlideStatus = (slide) => (slide.status = slide.status === 'ready' ? 'avatarReady' : 'edited')

const applyVideoColors = (video, colorProducer, match = () => true) => {
  video.slides.forEach((slide) => {
    if (match(slide.canvas.background)) {
      slide.canvas.background = colorProducer(slide.canvas.background)
      checkAndSetSlideStatus(slide)
    }
    slide.canvas.objects.forEach((obj) => {
      if (obj.fill?.type === 'linear') {
        obj.fill.colorStops.forEach((cs) => {
          if (match(cs.color, obj)) {
            cs.color = colorProducer(cs.color)
            checkAndSetSlideStatus(slide)
          }
        })
      } else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (match(style.fill, obj)) {
              style.fill = colorProducer(style.fill)
              checkAndSetSlideStatus(slide)
            }
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) {
              const fill = obj.styles[row][col].fill
              if (match(fill, obj)) {
                obj.styles[row][col].fill = colorProducer(fill)
                checkAndSetSlideStatus(slide)
              }
            }
          }
        }
      } else if (obj.type === 'group') {
        obj.objects.forEach((path) => {
          if (match(path.fill, obj)) {
            path.fill = colorProducer(path.fill)
            checkAndSetSlideStatus(slide)
          }
        })
      } else if (obj.fill && typeof obj.fill === 'string' && match(obj.fill)) {
        obj.fill = colorProducer(obj.fill)
        checkAndSetSlideStatus(slide)
      }
    })
  })
}

const countColorUsage = (video, color) => {
  if (!color) return 0
  let counter = 0
  video.slides.forEach((slide) => {
    if (slide.canvas.background === color) counter++
    slide.canvas.objects.forEach((obj) => {
      if (obj.fill?.type === 'linear') {
        obj.fill.colorStops.forEach((cs) => {
          if (cs.color === color) counter++
        })
      } else if (textTypes.includes(obj.type) && obj.styles && Object.keys(obj.styles).length) {
        if (Array.isArray(obj.styles) && obj.styles[0].style) {
          for (const { style } of obj.styles) {
            if (style.fill === color) counter++
          }
        } else {
          for (const row in obj.styles) {
            for (const col in obj.styles[row]) if (obj.styles[row][col].fill === color) counter++
          }
        }
      } else if (obj.type === 'group') {
        obj.objects.forEach((path) => {
          if (path.fill === color) counter++
        })
      } else if (obj.fill && typeof obj.fill === 'string' && obj.fill === color) counter++
    })
  })
  return counter
}

export { hexToRGB, getLuma, normalizeColor, grabVideoColors, applyVideoColors, countColorUsage }
