import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import Scrollbars from 'react-custom-scrollbars'

import Icon from '../../../../../components/Icon'

import { style } from '../constants'

import studio_avatar_sidebar from '../../../../../assets/images/studio-avatar-sidebar.png'
import photo_avatar from '../../../../../assets/images/public_custom_avatar/personal-cartoon.png'
import mascot_avatar from '../../../../../assets/images/public_custom_avatar/animated-mascot.png'
import selfie_avatar from '../../../../../assets/images/public_custom_avatar/webcam-avatar.png'
import AvatarItem from './avatarItem'
import AvatarDrawer from './avatarDrawer'
import { hideAvatarsCreation } from '../../../../../utils/config'

const plusIcon = <Icon name="plus" className="plus-icon" />

const CustomAvatarsTab = (props) => {
  const {
    data,
    title,
    avatarDrawer,
    activeAvatarTab,
    customAvatars,
    isAdmin,
    onAvatarClick,
    onChooseAvatar,
    setIsOpenPhotoAvatarModal,
    setActiveAvatar,
    onAvatarDrawerChange,
  } = props

  return (
    <div
      className="custom-avatars-tab"
      style={{ display: activeAvatarTab === 'custom' ? 'block' : 'none', ...style.marginTop24, ...style.positionRel }}
    >
      <Scrollbars className="avatars-scrollbar scrollbar">
        {customAvatars.length ? (
          <Row gutter={[16, 16]} style={{ paddingRight: 15, marginBottom: 24 }}>
            {customAvatars.map((a, i) => (
              <AvatarItem
                key={i}
                avatar={a}
                avatarIndex={i}
                data={data}
                isAdmin={isAdmin}
                onAvatarClick={onAvatarClick}
                onChooseAvatar={onChooseAvatar}
                setActiveAvatar={setActiveAvatar}
              />
            ))}
          </Row>
        ) : null}
        {!hideAvatarsCreation && (
          <>
            <h4 className="avatars-title">Create {customAvatars.length > 0 ? 'one more' : 'your first'} avatar</h4>
            <Row gutter={[16, 16]} style={{ padding: '0 15px 15px 0' }}>
              <Col className="avatar-item" span={12}>
                <Link to="/buy-avatar#studio" target="_blank">
                  <div className="card-add-avatar large titled">
                    {plusIcon}
                    <img src={studio_avatar_sidebar} />
                    <span className="avatar-name">Studio avatar</span>
                  </div>
                </Link>
              </Col>
              <Col className="avatar-item" span={12}>
                <Link to="/buy-avatar#selfie" target="_blank">
                  <div className="card-add-avatar large titled">
                    {plusIcon}
                    <img src={selfie_avatar} />
                    <span className="avatar-name">Selfie avatar</span>
                  </div>
                </Link>
              </Col>
              <Col className="avatar-item" span={12}>
                <div className="card-add-avatar large titled" onClick={() => setIsOpenPhotoAvatarModal(true)}>
                  {plusIcon}
                  <img src={photo_avatar} />
                  <span className="avatar-name">Photo avatar</span>
                </div>
              </Col>
              <Col className="avatar-item" span={12}>
                <Link to="/buy-avatar#mascot" target="_blank">
                  <div className="card-add-avatar large titled">
                    {plusIcon}
                    <img src={mascot_avatar} />
                    <span className="avatar-name">Mascot avatar</span>
                  </div>
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Scrollbars>
      <AvatarDrawer
        title={title}
        avatarDrawer={avatarDrawer}
        data={data}
        onAvatarDrawerChange={onAvatarDrawerChange}
        onChooseAvatar={onChooseAvatar}
      />
    </div>
  )
}

export default CustomAvatarsTab
