import { debounce } from 'throttle-debounce'
import { useCallback, useEffect, useRef, useState, useMemo } from 'react'

import music from '../../../../data/music'

import { musicURL } from './constants'
import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'

const defaultVolume = 0.18

export const useMusicTabState = (props) => {
  const { video, stopVideo, updateVideo, player, activeSlide } = props

  const notification = useElaiNotification()
  const [musicData, setMusicData] = useState(music)
  const [userMusic, setUserMusic] = useState()
  const [validateShift, setValidateShift] = useState(false)
  const [validateEndShift, setValidateEndShift] = useState()
  const [musicFilters, setMusicFilters] = useState({ genre: false, tags: [] })
  const [playingTrack, setPlayingTrack] = useState('idle')
  const [isMusicUploading, setIsMusicUploading] = useState(false)
  const [shift, setShift] = useState(video.data.musicShift || 0)
  const [endShift, setEndShift] = useState(video.data.musicEndShift || 0)

  const userPlan = useStore((stores) => stores.authStore.user.account.plan)
  const isUploadAvailable = !['trial', 'basic'].includes(userPlan)

  const [musicTab, setMusicTab] = useState(() =>
    video.data.musicIsUploaded && isUploadAvailable ? 'upload' : 'tracks',
  )

  const musicAudio = useRef(false)
  const shiftTimeout = useRef(false)
  const shiftPromiseReject = useRef()

  const durationOffset = useMemo(() => {
    return video.slides
      .slice(0, activeSlide)
      .reduce((duration, slide) => duration + (slide.duration || slide.approxDuration || 0), 0)
  }, [activeSlide, player.activePreview])

  const time =
    (player.activePreview && player.status !== 'playing' ? 0 : player.currentTime) +
    (player.activePreview ? durationOffset : 0)

  const playMusic = async () => {
    const audio = musicAudio.current
    audio.pause()
    if (shiftTimeout.current) clearTimeout(shiftTimeout.current)
    shiftPromiseReject.current?.()
    audio.currentTime = 0
    // totalDuration = 0
    if (shift < 0) {
      await new Promise((resolve) => audio.addEventListener('canplay', resolve, { once: true }))
      audio.currentTime = Math.abs(shift)
    }
  }

  const debounceVideo = useCallback(
    debounce(800, (data) => updateVideo({ data })),
    [],
  )

  const duration = video.slides.reduce((duration, slide) => duration + (slide.duration || slide.approxDuration || 0), 0)

  const createAudio = (url, musicShift = 0) => {
    const audio = new Audio(url)
    audio.volume = video.data?.musicVolume || defaultVolume
    audio.loop = true
    musicAudio.current?.pause?.()
    musicAudio.current = audio
    const newShift = video.data?.musicShift ?? musicShift
    if (shift === newShift || !newShift) playMusic()
    else setShift(newShift)
  }

  const pauseMusic = () => {
    if (musicAudio.current) musicAudio.current.pause()
    if (shiftTimeout.current) clearTimeout(shiftTimeout.current)
    shiftPromiseReject.current?.()
    setPlayingTrack('idle')
  }

  const applyMusic = ({ name, url, musicIsUploaded }) => {
    if (player.activePreview) {
      stopVideo()
    }
    pauseMusic()
    if (url === video.data.musicUrl) return
    updateVideo({
      data: {
        ...video.data,
        musicName: name,
        musicUrl: url,
        musicVolume: video.data?.musicVolume || defaultVolume,
        musicIsUploaded,
        shift,
      },
    })
  }

  const deleteMusic = () => {
    if (player.activePreview) {
      stopVideo()
    }
    pauseMusic()
    musicAudio.current = null
    delete video.data.musicName
    delete video.data.musicUrl
    video.data.musicIsUploaded = false
    updateVideo({ data: { ...video.data } })
  }

  const uploadMusic = (info) => {
    const { status, error, response } = info.file
    setIsMusicUploading(status === 'uploading')
    if (status === 'done') {
      const newAudio = {
        _id: response._id,
        name: response.name,
        url: response.url,
      }
      setUserMusic((prevValue) => ({ ...prevValue, uploads: [...prevValue.uploads, newAudio] }))
    } else if (status === 'error') {
      notification.error({ message: error.toString() })
    }
  }

  const beforeUpload = (file) => {
    if (file.size / 1024 / 1024 > 20) {
      notification.error({
        message: 'File must be smaller than 20MB',
      })
      return false
    }
    return true
  }

  const handleChangeMusicTab = (tab) => setMusicTab(tab)

  useEffect(() => {
    if (musicAudio.current) {
      musicAudio.current.currentTime = 0
    }
    if (player.activePreview) {
      if (playingTrack) {
        setPlayingTrack(null)
        pauseMusic()
      }
      if (video.data?.musicUrl) {
        createAudio(video.data.musicUrl)
        setPlayingTrack(video.data.musicUrl)
      }
    } else if (playingTrack === video.data?.musicUrl) {
      setPlayingTrack(null)
      pauseMusic()
    }
  }, [player.activePreview])

  useEffect(() => {
    if (shift !== undefined && playingTrack && playingTrack !== 'idle') {
      playMusic()
    }
  }, [shift])

  useEffect(() => {
    if (shift === undefined && video.data.musicShift) setShift(video.data.musicShift)
  }, [video?.data?.musicShift])

  useEffect(() => {
    if (shift === undefined && video.data.musicEndShift) setEndShift(video.data.musicEndShift)
  }, [video?.data?.musicEndShift])

  useEffect(() => {
    fetchUploads()
    return () => pauseMusic()
  }, [])

  useEffect(() => {
    let filteredMusic = [...music]
    if (musicFilters.genre) filteredMusic = filteredMusic.filter((m) => m.genre === musicFilters.genre)
    if (musicFilters.tags.length)
      filteredMusic = filteredMusic.filter((m) => m.tags.some((t) => musicFilters.tags.includes(t)))
    if (!musicFilters.genre && !musicFilters.tags.length && video.data?.musicName && !video.data.musicIsUploaded) {
      filteredMusic.sort((a, b) => {
        if (a.name === video.data.musicName) return -1
        if (b.name === video.data.musicName) return 1
        return 0
      })
    }
    setMusicData(filteredMusic)
  }, [musicFilters])

  /**
   * Play audio if exists and check on every tick
   */
  useEffect(() => {
    // audio is selected and active preview
    if (musicAudio.current && player.activePreview) {
      const audio = musicAudio.current
      // stop music on video search
      if (time < shift) {
        audio.pause()
      } else if (shift > 0) {
        // if video reach shift play music
        if (time >= shift) {
          audio?.play()
        }
        // play music no matter what ( shift 0 for example)
      } else {
        audio?.play()
      }
      if (endShift > 0) {
        // seems player have right duration of full video, so we can calculate it
        const stopMusicAt = duration - endShift
        if (time >= stopMusicAt) {
          audio?.pause()
        }
      }
    }
  }, [player.activePreview, player.currentTime])

  const handlePauseClick = () => {
    setPlayingTrack(null)
    pauseMusic()
  }
  const handleDraggerClick = () => {
    pauseMusic()
    stopVideo()
  }

  const fetchUploads = useCallback(async () => {
    const data = await request({
      method: 'get',
      url: `/uploads/music`,
    })
    setUserMusic(data)
  }, [])

  const onPlayClick = (file) => {
    if (player.activePreview) stopVideo()
    pauseMusic()
    createAudio(musicURL + file, video.data && video.data.musicUrl === musicURL + file ? shift : shift)
    setPlayingTrack(musicURL + file)
    musicAudio.current.play()
  }

  const handleShiftInput = (v) => {
    const audioEnd = duration - endShift

    if (v > 0) {
      if (endShift > 0 && audioEnd > 0 && v > audioEnd - 1) {
        setValidateShift(true)
      } else if (v > duration - 1) {
        setValidateShift(true)
      } else {
        setValidateShift(false)
      }
    } else {
      setValidateShift(false)
    }
    debounceVideo({ ...video.data, musicShift: v })
    setShift(v)
  }

  const handleEndShiftInput = (v) => {
    const audioEnd = duration - v

    if (v > 0) {
      if (audioEnd < 0) {
        setValidateEndShift(true)
      } else if (shift <= 0 && audioEnd > duration - 1) {
        setValidateEndShift(true)
      } else if (shift > 0 && audioEnd < shift - 1) {
        setValidateEndShift(true)
      } else {
        setValidateEndShift(false)
      }
    } else {
      setValidateEndShift(false)
    }
    debounceVideo({ ...video.data, musicEndShift: v })
    setEndShift(v)
  }

  return {
    shift,
    musicTab,
    endShift,
    userMusic,
    musicData,
    musicAudio,
    applyMusic,
    pauseMusic,
    deleteMusic,
    uploadMusic,
    onPlayClick,
    createAudio,
    validateShift,
    setUserMusic,
    beforeUpload,
    musicFilters,
    playingTrack,
    setMusicFilters,
    setPlayingTrack,
    validateEndShift,
    isMusicUploading,
    handleShiftInput,
    handlePauseClick,
    isUploadAvailable,
    handleDraggerClick,
    handleEndShiftInput,
    handleChangeMusicTab,
  }
}
