import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

const useNavigateToAnotherPage = ({ isVideoSaved, navigateUrl }) => {
  const navigate = useNavigate()

  const [isLeavingPopconfirmOpen, setIsLeavingPopconfirmOpen] = useState(false)

  const handleNavigate = (confirm) => {
    if (!isVideoSaved && !confirm) {
      setIsLeavingPopconfirmOpen(true)
      return
    }
    window.removeEventListener('beforeunload', promptForUnsavedChanges)
    navigate(navigateUrl)
  }

  const promptForUnsavedChanges = useCallback((event) => {
    // Safari requires returnValue to be set to display the prompt
    return (event.returnValue = 'You have unsaved changes. Are you sure you want to leave?')
  }, [])

  useEffect(() => {
    if (!isVideoSaved) window.addEventListener('beforeunload', promptForUnsavedChanges)
    else window.removeEventListener('beforeunload', promptForUnsavedChanges)
  }, [isVideoSaved])

  return {
    isLeavingPopconfirmOpen,
    setIsLeavingPopconfirmOpen,
    handleNavigate,
  }
}

export { useNavigateToAnotherPage }
