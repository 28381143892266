import React, { useState, useMemo } from 'react'
import { useDragLayer } from 'react-dnd'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Tooltip } from 'antd'
import { request } from '../../utils/api'
import Icon from '../../components/Icon'
import ItemPopconfirm from './itemPopconfirm'
import TranslateVideoModal from '../../components/TranslateVideoModal'
import { actionsWithConfirmation } from './constants'
import { useElaiNotification } from '../../hooks/useElaiNotification'
import { requestDuplicateVideo } from '../../utils/videoCreation/videoCreation'
import { useParams } from 'react-router'

const VideoItemActionMenu = ({
  video,
  isDeletedVideos,
  isVideoCreating,
  setIsVideoCreating,
  activeItem,
  fetchVideos,
  fetchFolders,
  setActiveItem,
  filterInfo,
  isAlwaysVisible,
}) => {
  const notification = useElaiNotification()
  const navigate = useNavigate()
  const { folderId } = useParams()
  const [videoForTranslate, setVideoForTranslate] = useState()
  const [popconfirmAction, setPopconfirmAction] = useState(false)
  const [listItemButtonVisible, setListItemButtonVisible] = useState(false)
  const [isTranslateVideoModalOpen, setIsTranslateVideoModalOpen] = useState(false)

  const isConvertedStoryVideo = useMemo(() => video.slides.every((s) => s.status !== 'story' && s.hasCanvas), [video])

  const handleClickVideoMenu = async (video, { key, domEvent }) => {
    domEvent.stopPropagation()
    if (actionsWithConfirmation.includes(key)) return setPopconfirmAction(key)
    if (key === 'download') downloadVideo(video)
    else if (key === 'duplicate') await copyVideo(video._id)
    else if (key === 'preview') navigate(`/preview/${video._id}`)
    else if (key === 'translate') translateVideo(video)
    else if (key === 'restore') restoreVideo(video._id)
    else if (key === 'history') navigate(`/history/${video._id}`)
  }

  const { itemType } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
  }))

  const saveVideoName = async (editingData) => {
    const { id, name } = editingData
    if (!name) return notification.error({ message: 'Video name is required' })
    await request({ method: 'patch', url: `/videos/${id}?from=name`, data: { name } })
    fetchVideos(filterInfo.condition)
  }

  const restoreVideo = async (id) => {
    setIsVideoCreating(id)
    await request({ method: 'patch', url: `/videos/${id}`, data: { deleted: false } })
    await fetchVideos(filterInfo.condition)
    setIsVideoCreating(false)
  }

  const deleteVideo = async (id) => {
    if (isDeletedVideos) await request({ method: 'delete', url: `videos/${id}` })
    else {
      await request({ method: 'patch', url: `/videos/${id}?from=delete`, data: { deleted: true } })
    }
    await fetchVideos(filterInfo.condition)
  }

  const downloadVideo = async (video) => {
    const link = document.createElement('a')
    link.href = video.url
    link.click()
  }

  const copyVideo = async (sourceId) => {
    setIsVideoCreating(sourceId)
    const id = await requestDuplicateVideo({ sourceId, folderId })
    setIsVideoCreating(false)
    if (id) await fetchVideos(filterInfo.condition)
  }

  const moveVideo = async (id) => {
    await request({ method: 'patch', url: `/videos/${video._id}`, data: { folderId: id || !!id } })
    await fetchVideos(filterInfo.condition)
  }

  const translateVideo = (video) => {
    setVideoForTranslate(video)
    setIsTranslateVideoModalOpen(true)
  }

  const getVideoMenuItems = (video) =>
    isDeletedVideos
      ? [
          {
            label: 'Restore',
            key: 'restore',
            icon: isVideoCreating === video._id ? <LoadingOutlined /> : <Icon name="redo" />,
          },
          {
            label: 'Delete',
            key: 'delete',
            icon: <Icon name="delete" />,
          },
        ]
      : [
          {
            label: 'Download',
            key: 'download',
            icon: <Icon name="download" />,
            disabled: !video.url,
          },
          {
            label: 'View edit history',
            key: 'history',
            icon: <Icon name="history" />,
          },
          {
            label: 'Rename',
            key: 'rename',
            icon: <Icon name="edit" />,
          },
          {
            label: 'Move',
            key: 'move',
            icon: <Icon name="move_folder" />,
          },
          {
            label: 'Duplicate',
            key: 'duplicate',
            icon: isVideoCreating === video._id ? <LoadingOutlined /> : <Icon name="copy" />,
          },
          {
            label: 'Preview',
            key: 'preview',
            icon: <Icon name="play" />,
          },
          {
            label: 'Translate',
            key: 'translate',
            icon: <Icon name="translate" />,
          },
          {
            label: 'Delete',
            key: 'delete',
            icon: <Icon name="delete" />,
          },
          {
            label: (
              <Tooltip
                title={
                  !isConvertedStoryVideo
                    ? 'This video has slides that were never converted from a story. Please convert the video to slides to create a template from it.'
                    : null
                }
              >
                <span className="label-with-tooltip">
                  <Icon name="convert" />
                  <span>Convert to template</span>
                </span>
              </Tooltip>
            ),
            key: 'convertToTemplate',
            disabled: !isConvertedStoryVideo,
          },
        ]

  return (
    <>
      <ItemPopconfirm
        item={video}
        isDeletedVideos={isDeletedVideos}
        popconfirmAction={popconfirmAction}
        setPopconfirmAction={setPopconfirmAction}
        setActiveItem={setActiveItem}
        setListItemButtonVisible={setListItemButtonVisible}
        saveItemName={saveVideoName}
        deleteItem={() => deleteVideo(video._id)}
        moveItem={moveVideo}
        filterInfo={filterInfo}
        fetchFolders={fetchFolders}
        itemComponent={
          <>
            <div style={{ width: '100%', height: 1 }}></div>
            {(isAlwaysVisible || (!activeItem && itemType !== 'video')) && (
              <Dropdown
                menu={{
                  items: getVideoMenuItems(video),
                  onClick: (e) => handleClickVideoMenu(video, e),
                }}
                placement="bottomRight"
                trigger={['click']}
                overlayClassName="video-dropdown-menu"
                onOpenChange={(open) => {
                  setListItemButtonVisible(open ? video._id : false)
                }}
              >
                <Button
                  icon={<Icon name="menu" />}
                  className={`btn-dropdown-menu ${listItemButtonVisible === video._id ? 'visible' : ''}`}
                  onClick={(e) => e.stopPropagation()}
                ></Button>
              </Dropdown>
            )}
          </>
        }
      />
      <TranslateVideoModal
        video={videoForTranslate}
        isOpen={isTranslateVideoModalOpen}
        setIsOpen={setIsTranslateVideoModalOpen}
      />
    </>
  )
}

export default VideoItemActionMenu
