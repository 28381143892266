import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { notification } from 'antd'
import { useStore } from '../store'
import { request } from '../utils/api'
import { queueMicrotask } from '../utils/helpers'

const { REACT_APP_CHAT_URL, REACT_APP_API_URL } = process.env
const CANVA_HANDLE_LOGIN_ENDPOINT = `/external-auth/canva/configuration/handle-login`
const CANVA_HANDLE_LOGIN_URL = REACT_APP_API_URL + CANVA_HANDLE_LOGIN_ENDPOINT

const BROADCAST_CHANNEL_NAME = 'elai-auth'
const BROADCAST_READY_MESSAGE = 'ready'

const redirectFieldsToSave = ['redirectUrl', 'state', 'redirectHash']

const redirectToChatApp = () => {
  let u = new URL(REACT_APP_CHAT_URL + '/auth')
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  if (!token || !refreshToken) return
  u.searchParams.set('token', localStorage.getItem('token'))
  u.searchParams.set('refreshToken', localStorage.getItem('refreshToken'))
  u.searchParams.set('role', localStorage.getItem('role'))
  return u.toString()
}

const redirectToCanvaApp = async () => {
  const { url } = await request({
    method: 'POST',
    url: CANVA_HANDLE_LOGIN_ENDPOINT,
    data: { state: localStorage.getItem('state') },
    withCredentials: true,
  })
  return url
}

const redirectRoutesConfig = {
  [REACT_APP_CHAT_URL]: {
    handler: redirectToChatApp,
  },
  [CANVA_HANDLE_LOGIN_URL]: {
    checkReadyState: () => {
      if (window.opener) return true

      const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME)
      bc.postMessage(BROADCAST_READY_MESSAGE)
      queueMicrotask(() => bc.close())

      notification.success({
        key: BROADCAST_CHANNEL_NAME,
        message: 'Please return to the canva app',
        duration: null,
      })

      return false
    },
    handler: redirectToCanvaApp,
  },
}

const redirectToExtApp = (authStore, redirectUrl) => {
  const redirect = (url) => {
    cleanupLocalStorage()
    window.location.replace(url)
  }

  const redirectConfig = redirectRoutesConfig[redirectUrl]
  if (!redirectConfig) return false

  if (typeof redirectConfig.checkReadyState === 'function') {
    if (!redirectConfig.checkReadyState()) return false
  }

  authStore.refreshSession().then(redirectConfig.handler).then(redirect)
  return true
}

const saveToLocalStorage = (authFields) => {
  for (const field in authFields) {
    localStorage.setItem(field, authFields[field])
  }
}

const cleanupLocalStorage = () => redirectFieldsToSave.forEach((field) => localStorage.removeItem(field))

const getRedirectUrlWithHash = () => {
  let redirectUrl = localStorage.getItem('redirectUrl')
  if (redirectUrl) {
    const hash = localStorage.getItem('redirectHash')
    return { redirectUrl, redirectHash: hash }
  }
  redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl')
  return { redirectUrl, redirectHash: window.location.hash }
}

const handleAuthFromChat = ({ authStore, params, redirectHash }) => {
  const redirect = () => window.location.replace('/' + redirectHash)
  // creating session if redirected from external app
  if (authStore.user) {
    redirect()
  } else {
    saveToLocalStorage({
      userId: params.get('id'),
      role: params.get('role'),
      refreshToken: params.get('refreshToken'),
    })
    authStore.refreshSession().then(redirect)
  }
}

const handleNewRegistration = (location) => {
  if (location.pathname === '/') {
    window.location.replace('/poll')
    return true
  } else {
    return false
  }
}

export const useExtAppAuth = () => {
  const authStore = useStore((stores) => stores.authStore)
  const redirecting = useRef(false)
  const location = useLocation()

  useEffect(() => {
    if (!window.opener) return

    const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME)
    bc.addEventListener('message', (e) => {
      if (e.data === BROADCAST_READY_MESSAGE) {
        bc.close()
        redirectToExtApp(authStore, localStorage.getItem('redirectUrl'))
      }
    })

    return () => bc.close()
  }, [])

  const isExternalAuth = () => {
    if (!['/login', '/auth', '/'].includes(location.pathname)) return false

    if (localStorage.getItem('isJustRegistered')) return handleNewRegistration(location)

    if (redirecting.current) return redirecting.current

    const { redirectUrl, redirectHash } = getRedirectUrlWithHash()
    const params = new URLSearchParams(location.search)
    if (!redirectUrl) {
      cleanupLocalStorage()
      return false
    }

    if (location.pathname === '/auth' && params.has('id') && params.has('refreshToken')) {
      saveRedirectUrl()

      handleAuthFromChat({ authStore, params, redirectHash })
      return true
    }

    const user = authStore.user
    if (!user) return false

    // it's chat auth, just ignore it at this moment
    if (redirectHash) return false

    redirecting.current = redirectToExtApp(authStore, redirectUrl)
    return redirecting.current
  }

  const saveRedirectUrl = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const redirectUrl = searchParams.get('redirectUrl')
    if (redirectUrl && redirectRoutesConfig[redirectUrl]) {
      saveToLocalStorage({ redirectUrl, state: searchParams.get('state'), redirectHash: window.location.hash })
    }
  }

  useEffect(() => {
    if (location.pathname !== '/' || location.hash.length > 1) return
    const { redirectUrl, redirectHash } = getRedirectUrlWithHash()
    if (!redirectHash) return
    redirectToExtApp(authStore, redirectUrl)
  }, [location.hash])

  /**
   * to log out user before canva authorization
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const redirectUrl = searchParams.get('redirectUrl')
    const state = searchParams.get('state')
    if (redirectUrl && state && location.pathname === '/signup' && authStore.user) {
      authStore.logout()
    }
  }, [location, authStore.user])

  saveRedirectUrl()

  return { isExternalAuth }
}
