import React from 'react'
import { MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Spin, Button, Upload, Tooltip, Segmented, InputNumber, Select } from 'antd'

import { VoiceModal } from './voiceModal'
import { SpeechEditor } from './speechEditor'
import Icon from '../../../../components/Icon'
import DurationInfo from '../../components/durationInfo'

import { apiUrl } from '../../../../utils/config'
import { customUploadRequest } from '../../../../utils/api'
import { checkObjectsAnimationTime } from '../../../../utils/canvas/canvas'

import { useVoiceState } from './useVoiceState'
import { useSlideDuration } from '../../../../hooks/useSlideDuration'

import { STYLE } from './constants'
import arrowUpIcon from '../../../../assets/images/arrow-up-icon.svg'
import arrowDownIcon from '../../../../assets/images/arrow-down-icon.svg'

const { Dragger } = Upload

const options = [
  { value: 'text', label: 'Speech text' },
  { value: 'file', label: 'Upload voice' },
  { value: 'silence', label: 'No speech' },
]

export const Voice = (props) => {
  const { video, data, player, setPlayer, audioCache, updateSlide, updateVideo, isSpeechUndo, undoLastChanges } = props

  const {
    voices,
    flagIcon,
    openModal,
    languages,
    userStatus,
    activeVoice,
    beforeUpload,
    getActiveVoice,
    isVoiceUploading,
    visibleVoiceModal,
    onChangeVoiceType,
    handleUploadingVoice,
    setVisibleVoiceModal,
    changeSilenceDuration,
    resetAudio,
    languageOptions,
    handleChangeLanguageForUploadVoice,
  } = useVoiceState({
    video,
    data,
    player,
    setPlayer,
    audioCache,
    updateSlide,
    updateVideo,
    checkObjectsAnimationTime,
  })

  const { slideDurationOrApprox, slideDurationLimit } = useSlideDuration({ slide: data })

  return (
    <div className="voice-controls" style={{ display: player.activePreview ? 'none' : '' }}>
      <div className="voice">
        {!player.activePreview && (
          <>
            <div style={STYLE.positionRel} id="speech-type-selection-wrapper">
              <Segmented
                value={data.voiceType}
                style={STYLE.marginBottom16}
                options={options}
                onChange={onChangeVoiceType}
              />
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: -4,
                  display: data.voiceType === 'text' ? 'block' : 'none',
                }}
              >
                <Button type="default" className="btn-select-voice" onClick={openModal}>
                  <span className="btn-select-content">
                    <img
                      src={flagIcon}
                      width="20"
                      className="flag-icon"
                      style={STYLE.marginRight16}
                      alt={activeVoice?.name}
                    />
                    <span>
                      {activeVoice?.character
                        ? `${activeVoice.character} (${activeVoice.icon.toUpperCase()})`
                        : activeVoice?.name}
                    </span>
                  </span>
                  <span className="icon-arrows">
                    <img src={arrowUpIcon} />
                    <img src={arrowDownIcon} />
                  </span>
                </Button>
              </div>
              <Select
                getPopupContainer={() => document.getElementById('speech-type-selection-wrapper')}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: 150,
                  display: data.voiceType === 'file' ? 'block' : 'none',
                }}
                showSearch
                options={languageOptions}
                defaultValue={data.language}
                onChange={handleChangeLanguageForUploadVoice}
              />
            </div>
            {data.voiceType === 'text' && (
              <DurationInfo data={data}>
                <Icon name="clock" />
                {data.duration ? '' : '~'}
                {slideDurationOrApprox}s used from {slideDurationLimit}s available
              </DurationInfo>
            )}
            <div style={{ position: 'relative', display: data.voiceType === 'text' ? 'block' : 'none', marginTop: 10 }}>
              <SpeechEditor
                data={{ ...data, activeVoice }}
                updateSlide={updateSlide}
                undoLastChanges={undoLastChanges}
                isSpeechUndo={isSpeechUndo}
                audioCache={audioCache}
                resetAudio={resetAudio}
                languages={languages}
              />
            </div>
            <Tooltip
              title="Please upgrade to paid plan to upload your own voice"
              overlayStyle={{
                visibility: userStatus === 'paid' ? 'hidden' : 'visible',
              }}
            >
              <div style={{ display: data.voiceType === 'file' ? 'block' : 'none' }}>
                {isVoiceUploading && <Spin size="large" />}
                {data.audioUrl &&
                  new URL(data.audioUrl).pathname.split('/').pop() !== 'speech.mp3' &&
                  !isVoiceUploading && (
                    <a href={`${data.audioUrl}`} target="_blank" rel="noreferrer">
                      🔉 {new URL(data.audioUrl).pathname.split('/').pop()}{' '}
                      {data.duration && !isNaN(data.duration) && <span>{`(${data.duration.toFixed(1)}s)`}</span>}
                    </a>
                  )}

                <Dragger
                  name="file"
                  action={`${apiUrl}/videos/voice/${video._id}/${data.id}`}
                  accept="audio/*"
                  disabled={userStatus !== 'paid'}
                  showUploadList={false}
                  customRequest={customUploadRequest}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadingVoice}
                >
                  <p className="ant-upload-drag-icon">
                    <MessageOutlined />
                  </p>
                  <p className="ant-upload-text">Upload an audio file with speech that avatar will say</p>
                  <p className="ant-upload-hint">
                    Please make sure audio is no longer than a {slideDurationLimit}s, there is just clear speech, and no
                    music. We are currently supporting most audio formats (mp3, wav, ogg, aac, m4a).
                  </p>
                </Dragger>
              </div>
            </Tooltip>
            <div style={{ display: data.voiceType === 'silence' ? 'block' : 'none' }}>
              <InputNumber
                addonBefore={
                  <>
                    <Tooltip title="No avatar and speech will be used. Just set the slide duration in seconds. Optionally you can add video(s) with sound to canvas.">
                      <QuestionCircleOutlined />
                    </Tooltip>{' '}
                    Duration (s)
                  </>
                }
                value={data.duration}
                min={1}
                max={600}
                controls
                disabled={player.status !== 'idle'}
                onChange={changeSilenceDuration}
              ></InputNumber>
            </div>
          </>
        )}
        <VoiceModal
          data={{ ...data, activeVoice }}
          updateSlide={updateSlide}
          voices={voices}
          languages={languages}
          updateVideo={updateVideo}
          visibleVoiceModal={visibleVoiceModal}
          setVisibleVoiceModal={setVisibleVoiceModal}
          getActiveVoice={getActiveVoice}
        />
      </div>
    </div>
  )
}
