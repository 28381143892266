import { useEffect, useRef } from 'react'
import { SketchPicker } from 'react-color'
import backgroundIcon from '../../../assets/images/color-wheel.png'

const ColorPicker = ({ color, isOpen, setIsOpen, onChangeColor, disabled }) => {
  const sketchPicker = useRef()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && sketchPicker.current && !sketchPicker.current.contains(e.target)) setIsOpen(false)
    }
    if (isOpen) document.addEventListener('mousedown', checkIfClickedOutside)
    else document.removeEventListener('mousedown', checkIfClickedOutside)
  }, [isOpen])

  return (
    <div ref={sketchPicker} style={{ position: 'relative' }}>
      <button
        className="color-item"
        style={{ backgroundImage: `url(${backgroundIcon})` }}
        onClick={() => !disabled && setIsOpen(true)}
      >
        +
      </button>
      {isOpen && (
        <div
          className="sketchpicker-container"
          style={{
            position: 'absolute',
            top: '-15px',
            left: '0',
            transform: `translateX(-${sketchPicker.current?.offsetLeft - 60}px)`,
          }}
        >
          <SketchPicker color={color} disableAlpha={true} onChangeComplete={({ hex }) => onChangeColor(hex)} />
        </div>
      )}
    </div>
  )
}

export default ColorPicker
