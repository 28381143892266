import { Button, Col, Row } from 'antd'
import Scrollbars from 'react-custom-scrollbars'
import { ArrowLeftOutlined } from '@ant-design/icons'

import FrameControls from './frameControls'
import InteractivityControls from './interactivityControls'
import { VideoControls } from './videoControls'
import { CommonControls } from './commonControls'
import { ElementsTabs } from './elementsTabs/index'
import { useElementsState } from './useElementsState'
import { AnimationControls } from './animationControls'
import { ShapeControls } from './components'
import ColorEditorButton from '../../colorsEditor/colorEditorButton'

import { SHAPES_TYPES } from '../../constants'
import { OBJECT_TYPES, focusIcon, saveIcon, STYLE, DICT, COMPRESS_ICON } from './constants'

import './elements.less'

const Elements = (props) => {
  const {
    video,
    data,
    activeSlide,
    canvasActiveObject,
    activeObjectType,
    setActiveObjectModifier,
    backArrow,
    setBackArrow,
    handleBackArrowClick,
  } = props

  const {
    canvasObjects,
    isOpenGradient,
    setIsOpenGradient,
    handleColorChange,
    activeObjectProps,
    isActiveObjectText,
    selectionWithAvatar,
    handleBackgroundApply,
    handleDetachBackground,
    handleBackgroundChanges,
    objectTypeInGroupCantBeInteractive,
  } = useElementsState({
    canvasActiveObject,
    setActiveObjectModifier,
  })

  const isBackground = canvasActiveObject?.bg
  const isListeningAvatar = activeObjectProps?.meta?.listeningAvatar
  const isVideo = activeObjectType === 'video'
  const canSetInteractivity = !isBackground && !isListeningAvatar && !isVideo

  return (
    <Scrollbars className="elements-scrollbar scrollbar">
      <div className="elements tab-content" id="elements-tab">
        {canvasActiveObject && backArrow.isVisible && (
          <div className="back-to-arrow" onClick={handleBackArrowClick}>
            <ArrowLeftOutlined /> <p>Return to {DICT[backArrow.tab]}</p>
          </div>
        )}
        <ElementsTabs
          video={video}
          slideCanvas={data.canvas}
          backArrow={backArrow}
          setBackArrow={setBackArrow}
          setIsOpenGradient={setIsOpenGradient}
          setActiveObjectModifier={setActiveObjectModifier}
          visible={!canvasActiveObject || isActiveObjectText}
        />

        {canvasActiveObject && !isActiveObjectText && (
          <div className="common-options">
            {!activeObjectProps.meta?.listeningAvatar &&
              !canvasActiveObject.bg &&
              canvasActiveObject.type !== 'activeSelection' && (
                <FrameControls
                  canvasActiveObject={canvasActiveObject}
                  setActiveObjectModifier={setActiveObjectModifier}
                />
              )}
            {canvasActiveObject.bg ? (
              <>
                <Button type="dashed" icon={saveIcon} style={STYLE.width100} onClick={handleBackgroundChanges}>
                  Save background changes
                </Button>
                <Button
                  type="dashed"
                  icon={COMPRESS_ICON}
                  style={{ width: '100%', marginTop: '15px' }}
                  onClick={handleDetachBackground}
                >
                  Detach from background
                </Button>
              </>
            ) : (
              !activeObjectProps.meta?.listeningAvatar &&
              OBJECT_TYPES.includes(activeObjectType) &&
              canvasActiveObject.type !== 'activeSelection' && (
                <div style={STYLE.button}>
                  <Button type="dashed" icon={focusIcon} style={STYLE.width100} onClick={handleBackgroundApply}>
                    Apply as background
                  </Button>
                </div>
              )
            )}
            {SHAPES_TYPES.includes(activeObjectType) && (
              <ShapeControls
                activeObjectProps={activeObjectProps}
                setActiveObjectModifier={setActiveObjectModifier}
                isOpenGradient={isOpenGradient}
                setIsOpenGradient={setIsOpenGradient}
              />
            )}
            {activeObjectType === 'path' && (
              <Row style={STYLE.marginBottom16}>
                <Col span={5}>
                  <h4 style={STYLE.marginColor}>Color</h4>
                </Col>
                <Col span={18}>
                  <ColorEditorButton color={activeObjectProps.fill} onChangeColor={handleColorChange} />
                </Col>
              </Row>
            )}
            {activeObjectType === 'group' &&
              canvasActiveObject.type !== 'activeSelection' &&
              canvasObjects.every((obj) => obj.type === 'path') && (
                <div style={STYLE.groupSelection}>
                  {canvasObjects.map((path, index) => (
                    <div key={`canvas-objects-${index}`} style={STYLE.margin010100}>
                      <ColorEditorButton
                        color={path.fill}
                        onChangeColor={(color) =>
                          setActiveObjectModifier({ type: 'SVG', change: 'fill', index: index, value: color })
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            {activeObjectType === 'video' && (
              <VideoControls
                duration={data.duration}
                canvasActiveObject={canvasActiveObject}
                activeObjectProps={activeObjectProps}
                setActiveObjectModifier={setActiveObjectModifier}
              />
            )}
            <CommonControls
              canvasActiveObject={canvasActiveObject}
              activeObjectProps={activeObjectProps}
              activeObjectType={activeObjectType}
              setActiveObjectModifier={setActiveObjectModifier}
              isActiveSelection={canvasActiveObject.type === 'activeSelection'}
              selectionWithAvatar={selectionWithAvatar}
            />
            {!selectionWithAvatar && !activeObjectProps.meta?.listeningAvatar && (
              <AnimationControls
                data={data}
                activeObjectProps={activeObjectProps}
                setActiveObjectModifier={setActiveObjectModifier}
              />
            )}
            {canSetInteractivity && (
              <InteractivityControls
                video={video}
                activeSlide={activeSlide}
                activeObjectProps={activeObjectProps}
                setActiveObjectModifier={setActiveObjectModifier}
                objectTypeInGroupCantBeInteractive={objectTypeInGroupCantBeInteractive}
              />
            )}
          </div>
        )}
      </div>
    </Scrollbars>
  )
}

export default Elements
