import { useEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Row, InputNumber, Space, Tooltip, Typography } from 'antd'

import { formatSeconds } from '../useVideoControls'
import LazySlider from '../../../../../../components/LazySlider'

import { STYLE } from '../constants'

export const Trim = (props) => {
  const { canvasActiveObject, onStep, onTrimChange, onTrimStartChange, onTrimEndChange } = props

  const [trimStart, setTrimStart] = useState(canvasActiveObject.trimStart)
  const [trimEnd, setTrimEnd] = useState(canvasActiveObject.trimEnd)

  const handleTrimStartChange = (value) => {
    setTrimStart(value)
  }

  const handleTrimEndChange = (value) => {
    setTrimEnd(value)
  }

  useEffect(() => {
    const trimStateUpdate = debounce(800, () => onTrimStartChange(trimStart))
    if (trimStart !== canvasActiveObject.trimStart) trimStateUpdate()
  }, [trimStart])

  useEffect(() => {
    const trimStateUpdate = debounce(1000, () => onTrimEndChange(trimEnd))
    if (trimEnd !== canvasActiveObject.trimEnd) trimStateUpdate()
  }, [trimEnd])

  const elementDuration = canvasActiveObject.getElement().duration

  return (
    <>
      <Row style={STYLE.marginTop14}>
        <Col span={4} style={STYLE.marginTop4}>
          <Space>
            <h4>Trim</h4>
            <Tooltip title="To trim a video, just drag circles from the beginning or end of the slider">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={18} style={STYLE.marginLeft20}>
          <Space direction="horisontal">
            <Space direction="vertical">
              <InputNumber
                value={trimStart}
                precision={2}
                stringMode={false}
                onChange={handleTrimStartChange}
                defaultValue={0}
              />
              <Typography.Text>Start (s)</Typography.Text>
            </Space>
            <Space direction="vertical">
              <InputNumber
                value={trimEnd}
                precision={2}
                stringMode={false}
                onChange={handleTrimEndChange}
                defaultValue={0}
                on
              />
              <Typography.Text>End (s)</Typography.Text>
            </Space>
          </Space>
        </Col>
      </Row>
      <Row style={STYLE.trimSlider} align="middle">
        <Col span={24}>
          <LazySlider
            value={[canvasActiveObject.trimStart, canvasActiveObject.trimEnd || elementDuration]}
            min={0}
            max={elementDuration}
            tooltip={{ formatter: formatSeconds }}
            step={0.01}
            range
            allowCross={false}
            onStep={onStep}
            onChange={onTrimChange}
          />
        </Col>
      </Row>
    </>
  )
}
