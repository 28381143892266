import { Link } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars'
import { Col, Row, Card, Modal, Button, Tooltip } from 'antd'

import { useVoiceModal } from './useVoiceModal'
import { hideAvatarsCreation } from '../../../../../utils/config'

import { CLOSE_ICON } from './constants'

import './voiceModal.less'
import { ModalTitle, VoiceModalCardTitle, VoiceModalHeader, VoiceList } from './components'

export const VoiceModal = (props) => {
  const { data, updateSlide, updateVideo, visibleVoiceModal, setVisibleVoiceModal, getActiveVoice, voices, languages } =
    props

  const {
    user,
    closeModal,
    voicesList,
    voicesTags,
    applyVoice,
    activeVoice,
    selectedTags,
    isActiveVoice,
    onSwitchClick,
    setActiveVoice,
    setSelectedTags,
    afterCloseModal,
    onLanguageSelect,
    playVoicePreview,
    pauseVoicePreview,
    isPremiumAvailable,
    voicePreviewPlayer,
    premiumVoicesVisible,
    laguageFilterOptions,
    applyVoiceToAllSlides,
    setVoicePreviewPlayer,
  } = useVoiceModal({ data, getActiveVoice, updateVideo, setVisibleVoiceModal, updateSlide, visibleVoiceModal, voices })

  return (
    <Modal
      title={<ModalTitle />}
      closeIcon={CLOSE_ICON}
      destroyOnClose
      open={visibleVoiceModal}
      footer={null}
      width={1350}
      className="voice-modal"
      onCancel={closeModal}
      afterClose={afterCloseModal}
    >
      <VoiceModalHeader
        languages={languages}
        voicesTags={voicesTags}
        selectedTags={selectedTags}
        onSwitchClick={onSwitchClick}
        setSelectedTags={setSelectedTags}
        onLanguageSelect={onLanguageSelect}
        currentLanguage={activeVoice.language}
        premiumVoicesVisible={premiumVoicesVisible}
        laguageFilterOptions={laguageFilterOptions}
      />
      <Scrollbars className="voice-scrollbar scrollbar">
        <Row gutter={[16, 16]} className="horizontal-padding">
          {hideAvatarsCreation ? null : (
            <Col key="voice-cloning" lg={6} md={8} sm={12} xs={24}>
              <Link to="/buy-voice" target="_blank">
                <Card className="voice-item create-custom-item" title={<VoiceModalCardTitle />} />
              </Link>
            </Col>
          )}
          {voicesList.map((v, index) => (
            <VoiceList
              key={index}
              voice={v}
              index={index}
              isAdmin={user.isAdmin}
              activeVoice={activeVoice}
              isActiveVoice={isActiveVoice}
              setActiveVoice={setActiveVoice}
              playVoicePreview={playVoicePreview}
              pauseVoicePreview={pauseVoicePreview}
              voicePreviewPlayer={voicePreviewPlayer}
              isPremiumAvailable={isPremiumAvailable}
              setVoicePreviewPlayer={setVoicePreviewPlayer}
            />
          ))}
        </Row>
      </Scrollbars>
      <Row className="buttom-row">
        <Col span={24} className="button-col">
          <Tooltip
            title={
              !isPremiumAvailable && !!activeVoice.voice.premium
                ? 'Upgrade to Advanced or Enterprise plan to use premium voices'
                : `Apply voice to all ${data.avatar.gender} avatars`
            }
          >
            <Button
              type="primary"
              className="btn-apply-all"
              disabled={!isPremiumAvailable && !!activeVoice.voice.premium}
              onClick={applyVoiceToAllSlides}
            >
              Apply to all
            </Button>
          </Tooltip>
          <Tooltip
            overlayStyle={{ visibility: !isPremiumAvailable && !!activeVoice.voice.premium ? 'visible' : 'hidden' }}
            title="Upgrade to Advanced or Enterprise plan to use premium voices"
          >
            <Button type="primary" disabled={!isPremiumAvailable && !!activeVoice.voice.premium} onClick={applyVoice}>
              Apply voice
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Modal>
  )
}
