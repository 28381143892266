import { PlusOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'
import React, { useEffect, useState, useRef } from 'react'
import { Upload, Modal, Spin, Button, Input } from 'antd'

import { UploadItem } from './uploadItem'

import { useStore } from '../../../../store'
import { request, uploadDirectlyToS3 } from '../../../../utils/api'

import './fileUploader.less'
import { useElaiNotification } from '../../../../hooks/useElaiNotification'

const { Search } = Input

const PAGE_SIZE = 30

const FileUploader = (props) => {
  const {
    visible,
    isFrames,
    fetchFrames,
    onChooseFile,
    isVideoAllowed,
    isShouldUpdateUploads,
    setShouldUpdateUploads,
    handleUploadRemoved,
    autoChooseOnUpload = false,
  } = props

  const notification = useElaiNotification()
  const uploader = useRef(null)
  const authStore = useStore((stores) => stores.authStore)

  const [uploads, setUploads] = useState({
    files: undefined,
    hasMore: false,
    offset: 0,
    query: '',
    loading: true,
    syncing: false,
  })
  const [filter, setFilter] = useState('')
  const [preview, setPreview] = useState()
  const [progress, setProgress] = useState([])
  const [isOpenPreview, setIsOpenPreview] = useState(false)
  const [isOpenPreviewLoader, setIsOpenPreviewLoader] = useState(false)

  const fetchUploads = async () => {
    const { offset, query } = uploads
    const limit = PAGE_SIZE - !offset
    setUploads((uploads) => ({ ...uploads, loading: true }))
    const data = await request({
      method: 'get',
      url: `/uploads${isFrames ? '/frames' : ''}?offset=${offset}&limit=${limit}&q=${query}`,
    })
    if (!data) return setUploads((uploads) => ({ ...uploads, loading: false }))
    const filesData = data.uploads
    const files = [
      ...(uploads.files || []),
      ...(isVideoAllowed ? filesData : filesData?.filter((file) => file.type !== 'video')),
    ]
    setUploads({ files, hasMore: data.hasMore, offset: files.length, query, loading: false })
  }

  const beforeUpload = (file) => {
    const limitMb = authStore.user.account.plan === 'basic' ? 20 : 200
    if (file.size / 1024 / 1024 > limitMb) {
      notification.error({
        message: `File must be smaller than ${limitMb}MB.${
          authStore.user.account.plan === 'basic'
            ? ' If you want to upload more than 20MB please upgrade to Advanced or Enterprise plan.'
            : ''
        }`,
      })
      return false
    }

    if (file.type.startsWith('video') && !file.type.endsWith('mp4'))
      notification.warning({
        duration: 20,
        key: 'upload-convert',
        message:
          'Your file will be converted to mp4, which might take time. Please don’t close this page until you see the file in your sidebar.',
      })
    else if (file.size > 50 * 1024 * 1024)
      notification.warning({
        duration: 15,
        key: 'upload-large',
        message: 'Please keep this page open before you see the file in your sidebar.',
      })
    return true
  }

  const reloadUpdates = async () => {
    const { query } = uploads
    setUploads((uploads) => ({ ...uploads, syncing: true }))
    const data = await request({
      method: 'get',
      url: '/uploads',
    })
    const files = [...(isVideoAllowed ? data.uploads : data.uploads?.filter((file) => file.type !== 'video'))]
    setUploads({ files, hasMore: data.hasMore, offset: files.length, query, loading: false, syncing: false })
  }

  useEffect(() => {
    if (isShouldUpdateUploads) {
      reloadUpdates()
      setShouldUpdateUploads(false)
    }
  }, [isShouldUpdateUploads])

  useEffect(() => {
    if (fetchFrames && isFrames) {
      fetchUploads()
    }
  }, [fetchFrames, isFrames])

  useEffect(() => {
    if (!isFrames) {
      fetchUploads()
    }
  }, [uploads.query])

  useEffect(() => {
    if (uploads.syncing || !uploads.files) return

    const updateUploadsStatus = () => {
      setShouldUpdateUploads(true)
    }
    let updateInterval
    const convertingUploads = uploads.files?.filter((file) => file.status === 'converting')
    if (convertingUploads?.length) {
      updateInterval = setInterval(updateUploadsStatus, 30000)
    }
    return () => clearInterval(updateInterval)
  }, [uploads.syncing, uploads.files])

  const handleUploadProgress = ({ isOpen, percent, fileUid, fileUrl }) => {
    const currentProgressIndex = progress.findIndex((p) => p.fileUid === fileUid)
    let updatedProgress
    if (fileUrl) updatedProgress = { isOpen, percent, fileUid, fileUrl }
    else updatedProgress = { isOpen, percent, fileUid }
    const newProgress = [
      ...progress.slice(0, currentProgressIndex),
      updatedProgress,
      ...progress.slice(currentProgressIndex + 1),
    ]
    setProgress(newProgress)
  }

  const handleChange = async ({ file, fileList }) => {
    if (file.status === 'done' && !file.response) file.status = 'error'

    if (file.status === 'done') {
      handleUploadProgress({ isOpen: false, percent: file.percent, fileUid: file.uid, fileUrl: file.url })
    } else {
      handleUploadProgress({ isOpen: true, percent: file.percent, fileUid: file.uid })
    }

    // sort new uploads to begin of the list
    if (fileList[fileList.length - 1].uid.includes('rc-upload')) {
      const withoutUploads = fileList.filter((f) => !f.uid.includes('rc-upload'))
      const newUploads = fileList.filter((f) => f.uid.includes('rc-upload'))
      fileList = [...newUploads, ...withoutUploads]
    }

    if (file?.status === 'error') {
      fileList = fileList.filter((listFile) => listFile.uid !== file.uid)
      notification.error({ message: 'Error uploading file' })
    }

    if (file.status === 'done' && file.response?.upload?.status === 'converting') {
      return setShouldUpdateUploads(true)
    }

    if (file.status === 'done' && autoChooseOnUpload) onChooseFile(file.response)

    if (file.response) {
      fileList = fileList.map((file) => (file.response ? file.response : file))
    }
    setUploads((uploads) => ({ ...uploads, files: [...fileList], offset: fileList.length }))
  }

  const handleRemove = async (file, usedOnVideos) => {
    const { confirm } = Modal
    return new Promise((resolve, reject) => {
      confirm({
        title: usedOnVideos
          ? `Seems like this file is used in some of your videos. Are you sure you want to proceed? File ${file.name} will be removed from all videos in your account.`
          : `Are you sure you want to delete ${file.name}?`,
        onOk: async () => {
          await request({ method: 'delete', url: `/uploads/${file._id}` })
          setUploads((uploads) => ({
            ...uploads,
            files: uploads.files.filter((f) => f._id !== file._id),
            offset: uploads.offset - 1,
          }))
          resolve(true)
          if (usedOnVideos) handleUploadRemoved(file.url)
        },
        onCancel: () => {
          reject(true)
        },
      })
    })
  }
  const handlePreview = async (file) => {
    setPreview({
      ...preview,
      url: file.url,
      thumbnail: file.thumbnail,
      title: file.name,
      type: file.type,
    })
  }

  const handleCancel = () => setIsOpenPreview(false)

  const uploadProps = {
    accept: !isFrames
      ? 'image/jpeg, image/png, image/svg+xml'.concat(
          isVideoAllowed ? ', video/mp4, video/quicktime, video/webm, video/x-ms-wmv, image/gif' : '',
        )
      : 'image/svg+xml',
    listType: 'picture-card',
    fileList: uploads.files,
    multiple: true,
    data: isFrames ? { frame: true } : {},
    beforeUpload: beforeUpload,
    onChange: handleChange,
    customRequest: uploadDirectlyToS3,
  }

  const handleAddClick = () => {
    // without name field not possible to change color of the svg
    const file = { ...preview, name: preview.title }
    onChooseFile(file)
    setIsOpenPreview(false)
  }

  return (
    <div className="uploads-media tab-content" style={{ display: visible ? 'flex' : 'none', marginRight: '-12px' }}>
      {!isFrames && (
        <Search
          value={filter}
          placeholder="Search uploaded media"
          className="media-items-controls"
          onChange={(e) => {
            setFilter(e.target.value)
          }}
          onSearch={() => {
            if (filter !== uploads.query) {
              setUploads((uploads) => ({ ...uploads, files: [], offset: 0, query: filter }))
            }
          }}
          enterButton
          allowClear
        />
      )}
      <div className="uploads-media-wrapper">
        <Scrollbars
          style={{ minHeight: 400 }}
          className={`uploads-scrollbar scrollbar ${
            (uploads.query && !uploads.files?.length) || (isFrames && !authStore.user.isAdmin) ? 'disabled-upload' : ''
          }`}
        >
          {uploads.files === undefined || (uploads.files.length === 0 && uploads.loading) ? (
            <Spin style={{ width: '100%', padding: '16px 0' }} />
          ) : (
            <>
              <Upload
                ref={uploader}
                {...uploadProps}
                itemRender={(ReactComponent, file, fileList) => (
                  <UploadItem
                    file={file}
                    progress={progress.find((f) => f.fileUid === file.uid)}
                    isFrames={isFrames}
                    setFile={onChooseFile}
                    handleRemove={handleRemove}
                    handlePreview={handlePreview}
                    setIsOpenPreview={setIsOpenPreview}
                  />
                )}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
              {uploads.files && uploads.hasMore && (
                <Button
                  type="primary"
                  className="show-more"
                  ghost
                  block
                  onClick={fetchUploads}
                  loading={uploads.loading}
                >
                  Show more
                </Button>
              )}
            </>
          )}
          {uploads.query && !uploads.files?.length && !uploads.loading && (
            <p style={{ textAlign: 'center' }}>Nothing found</p>
          )}
        </Scrollbars>
      </div>
      <Modal
        open={isOpenPreview}
        title={isFrames ? null : preview?.title}
        className="media-preview-modal"
        destroyOnClose
        onCancel={handleCancel}
        footer={[
          <Button type="primary" icon={<PlusOutlined />} key="add" onClick={handleAddClick}>
            Add
          </Button>,
        ]}
      >
        {isOpenPreviewLoader || !preview ? (
          <Spin style={{ width: '100%', height: '50px', padding: '15px' }} />
        ) : preview.type === 'video' ? (
          <video
            src={preview.url}
            crossOrigin="anonymous"
            onLoadedMetadata={() => setIsOpenPreviewLoader(false)}
            controls
          />
        ) : (
          <img alt="example" crossOrigin="anonymous" src={preview.url} onLoad={() => setIsOpenPreviewLoader(false)} />
        )}
      </Modal>
    </div>
  )
}

export default FileUploader
